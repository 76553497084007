import { NavLink } from "react-router-dom"
import type { HTMLAttributes, ReactNode } from "react"
import { cn } from "@/shared/shadcn/lib/utils"
import { Separator } from "@/shared/shadcn/components/ui/separator"

interface ProcessPageProps extends HTMLAttributes<HTMLDivElement> {}

export function ProcessPage({
  children,
  className,
  ...props
}: ProcessPageProps) {
  return (
    <div className={cn("flex flex-col pr-2 pt-2", className)} {...props}>
      {children}
    </div>
  )
}

interface ProcessPageNavigationProps extends HTMLAttributes<HTMLDivElement> {}

export function ProcessPageNavigation({
  children,
  className,
  ...props
}: ProcessPageNavigationProps) {
  return (
    <aside className={cn("fixed sm:w-56", className)} {...props}>
      <nav className="pb-12">
        <div className="space-y-4">{children}</div>
      </nav>
    </aside>
  )
}

interface ProcessPageNavLinkGroupProps extends HTMLAttributes<HTMLDivElement> {}

export function ProcessPageNavLinkGroup({
  children,
  className,
  ...props
}: ProcessPageNavLinkGroupProps) {
  return (
    <div className={cn("pb-2", className)} {...props}>
      {children}
    </div>
  )
}

interface ProcessPageNavLinkGroupTitleProps
  extends HTMLAttributes<HTMLDivElement> {}

export function ProcessPageNavLinkGroupTitle({
  children,
  className,
  ...props
}: ProcessPageNavLinkGroupTitleProps) {
  return (
    <h2
      className={cn(
        "mb-2 px-4 text-lg font-semibold tracking-tight",
        className,
      )}
      {...props}
    >
      {children}
    </h2>
  )
}

interface ProcessPageNavLinkListProps extends HTMLAttributes<HTMLDivElement> {}

export function ProcessPageNavLinkList({
  children,
  className,
  ...props
}: ProcessPageNavLinkListProps) {
  return (
    <div className={cn("space-y-1", className)} {...props}>
      {children}
    </div>
  )
}

interface ProcessPageNavLinkSeparatorProps
  extends HTMLAttributes<HTMLDivElement> {}

export function ProcessPageNavLinkSeparator({
  className,
  ...props
}: ProcessPageNavLinkSeparatorProps) {
  return (
    <div className={cn("px-4", className)} {...props}>
      <Separator />
    </div>
  )
}

interface ProcessPageNavLinkProps {
  to: string
  children: ReactNode
  end?: boolean
}

ProcessPageNavLink.defaultProps = {
  end: false,
}

export function ProcessPageNavLink({
  to,
  children,
  end,
}: ProcessPageNavLinkProps) {
  return (
    <NavLink
      to={to}
      end={end ?? false}
      className={({ isActive }) =>
        isActive
          ? "inline-flex h-10 w-full items-center justify-start whitespace-nowrap rounded-md bg-secondary px-4 py-2 text-sm font-medium text-secondary-foreground ring-offset-background transition-colors hover:bg-secondary/80 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
          : "inline-flex h-10 w-full items-center justify-start whitespace-nowrap rounded-md px-4 py-2 text-sm font-medium ring-offset-background transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
      }
    >
      {children}
    </NavLink>
  )
}

interface ProcessPageMainProps extends HTMLAttributes<HTMLDivElement> {}

export function ProcessPageMain({
  children,
  className,
  ...props
}: ProcessPageMainProps) {
  return (
    <main
      className={cn("space-y-2 overflow-x-hidden sm:ml-60", className)}
      {...props}
    >
      {children}
    </main>
  )
}
