import type { HTMLAttributes } from "react"
import { cn } from "@/shared/shadcn/lib/utils"

export interface MilestonePageProps extends HTMLAttributes<HTMLDivElement> {}

export function MilestonePage({
  children,
  className,
  ...props
}: MilestonePageProps) {
  return (
    <div className={cn("space-y-4", className)} {...props}>
      {children}
    </div>
  )
}

export interface MilestonePageHeaderProps
  extends HTMLAttributes<HTMLDivElement> {}

export function MilestonePageHeader({
  children,
  className,
  ...props
}: MilestonePageHeaderProps) {
  return (
    <div
      className={cn(
        "flex items-center justify-between border-b pb-2",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}

export interface MilestonePageTitleProps
  extends HTMLAttributes<HTMLHeadingElement> {}

export function MilestonePageTitle({
  children,
  className,
  ...props
}: MilestonePageTitleProps) {
  return (
    <h2
      {...props}
      className={cn(
        "scroll-m-20 text-3xl font-semibold tracking-tight first:mt-0",
        className,
      )}
    >
      {children}
    </h2>
  )
}

export interface MilestonePageActionsProps
  extends HTMLAttributes<HTMLDivElement> {}

export function MilestonePageActions({
  children,
  className,
  ...props
}: MilestonePageActionsProps) {
  return (
    <div className={cn("flex items-center space-x-2", className)} {...props}>
      {children}
    </div>
  )
}

export interface MilestonePageContentProps
  extends HTMLAttributes<HTMLDivElement> {}

export function MilestonePageContent({
  children,
  className,
  ...props
}: MilestonePageContentProps) {
  return (
    <div className={cn("space-y-4", className)} {...props}>
      {children}
    </div>
  )
}

export interface MilestonePageContentMenuProps
  extends HTMLAttributes<HTMLDivElement> {}

export function MilestonePageContentMenu({
  children,
  className,
  ...props
}: MilestonePageContentMenuProps) {
  return (
    <div
      className={cn("flex items-center justify-between", className)}
      {...props}
    >
      {children}
    </div>
  )
}
