import { H2 } from "@/shared/components/typography"
import DeliveryState from "@/routes/deliveries/[deliveryId]/delivery/overview/_components/delivery-state"
import { useParams } from "react-router-dom"
import { useGetDeliveryQuery } from "@/services/backend/deliveries/delivery/service"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import PackageContents from "@/routes/deliveries/[deliveryId]/delivery/overview/_components/package-contents"

export default function OverviewRoute() {
  const { deliveryId } = useParams()
  const { data, isLoading, error } = useGetDeliveryQuery({
    id: deliveryId as string,
  })

  if (isLoading || data == null) {
    return (
      <div className="animate-pulse space-y-4 text-gray-300">
        <H2>Ansprechpartner</H2>
        <div className="block max-w-sm rounded-lg border border-gray-200 bg-white p-4 shadow sm:max-w-md">
          <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-300">
            Lade Kontakte
          </h5>
          <div className="mb-4 h-2.5 w-48 rounded-full bg-gray-200" />
          <div className="mb-2.5 h-2 max-w-[300px] rounded-full bg-gray-200" />
          <div className="mb-2.5 h-2 max-w-[300px] rounded-full bg-gray-200" />
          <div className="mb-2.5 h-2 max-w-[300px] rounded-full bg-gray-200" />
          <div className="mb-2.5 h-2 max-w-[300px] rounded-full bg-gray-200" />
        </div>
      </div>
    )
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <div className="space-y-4">
      <H2>Übersicht</H2>
      {deliveryId != null && <DeliveryState deliveryId={deliveryId} />}
      <PackageContents />
    </div>
  )
}
