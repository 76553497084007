import type { ColumnDef, Table as ReactTable } from "@tanstack/react-table"
import { useState } from "react"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import {
  DataTable,
  DataTableBrowserPagination,
  DataTableContainer,
  DataTableFilters,
  useDataTableController,
} from "@/shared/components/data-table"
import type { ListRequest } from "@/services/backend/processes/process/service"
import { useCustomersListQuery } from "@/services/backend/customers/customer/service"
import type { Customer } from "@/services/backend/customers/customer/customer"
import { NavLink } from "react-router-dom"
import { Button } from "@/shared/shadcn/components/ui/button"
import { Search } from "lucide-react"
import { CreateCustomerForm } from "./create-customer-form"

const tableColumns: ColumnDef<Customer>[] = [
  {
    id: "Kunde",
    accessorKey: "name",
    header: "Kunde",
    cell: ({ row }) => {
      const customerData = row.original
      return <div>{customerData.name}</div>
    },
  },
  {
    id: "Optionen",
    accessorKey: "id",
    header: "Optionen",
    cell: ({ row }) => (
      <NavLink to={`${row.original.id}`}>
        <Button variant="outline" size="sm" className="-mb-2.5 -mt-1.5">
          <Search className="h-4 w-4" />
        </Button>
      </NavLink>
    ),
  },
]

export function CustomersListTable() {
  const [request] = useState<ListRequest>({
    limit: 50,
    offset: 0,
  })
  const {
    data: list,
    isLoading,
    isFetching,
    error,
  } = useCustomersListQuery(request, {
    refetchOnMountOrArgChange: true,
  })

  const table = useDataTableController(tableColumns, list?.data || [])

  if (isLoading || isFetching) {
    return <Skeleton table={table} columns={tableColumns} />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <DataTableContainer>
      <DataTableFilters className="flex justify-between space-x-2">
        <div className="flex items-center space-x-2">
          <CreateCustomerForm />
        </div>
      </DataTableFilters>
      <DataTable table={table} columns={tableColumns} />
      <DataTableBrowserPagination table={table} />
    </DataTableContainer>
  )
}

function Skeleton<TData>(props: {
  table: ReactTable<TData>
  columns: ColumnDef<TData>[]
}) {
  const { table, columns } = props
  return (
    <DataTableContainer className="animate-pulse">
      <DataTable table={table} columns={columns} />
      <DataTableBrowserPagination table={table} />
    </DataTableContainer>
  )
}
