import {
  MilestonePage,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/layout/milestone-page"
import { Outlet } from "react-router"

export function AnalyticsRoute() {
  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>Zählung</MilestonePageTitle>
      </MilestonePageHeader>
      <MilestonePageContent>
        <Outlet />
      </MilestonePageContent>
    </MilestonePage>
  )
}
