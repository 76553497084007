import { Button } from "@/shared/shadcn/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/shadcn/components/ui/dialog"
import { useDeleteApiKeyMutation } from "@/services/backend/api-key/service"
import { Trash2 } from "lucide-react"

interface DeleteApiKeyFormProps {
  id: string
}

export function DeleteApiKeyForm({ id }: DeleteApiKeyFormProps) {
  const [deleteApiKey] = useDeleteApiKeyMutation()

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="bg-red-500 text-white" variant="outline">
          <Trash2 className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Key löschen</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Möchten Sie den Key wirklich löschen? Verbundene Systeme werden keine
          neuen Anfragen mehr schicken können.
        </DialogDescription>
        <div className="flex items-center space-x-2">
          <Button
            className="bg-red-500"
            type="submit"
            onClick={() =>
              deleteApiKey({
                id,
              })
            }
          >
            Löschen
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
