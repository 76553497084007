import { HTMLAttributes, useState } from "react"
import type {
  ColumnDef,
  RowSelectionState,
  SortingState,
  Table as ReactTable,
  VisibilityState,
} from "@tanstack/react-table"
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { ChevronLeft, ChevronRight } from "lucide-react"
import { cn } from "@/shared/shadcn/lib/utils"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/shadcn/components/ui/table"
import {
  Pagination,
  PaginationContent,
  PaginationItem,
} from "@/shared/components/pagination"
import { Button } from "@/shared/shadcn/components/ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/shadcn/components/ui/select"

export interface DataTableContainer extends HTMLAttributes<HTMLDivElement> {}

export function DataTableContainer({
  children,
  className,
  ...props
}: DataTableContainer) {
  return (
    <div className={cn("space-y-4", className)} {...props}>
      {children}
    </div>
  )
}

export interface FiltersProps extends HTMLAttributes<HTMLDivElement> {}

export function DataTableFilters({
  children,
  className,
  ...props
}: FiltersProps) {
  return (
    <div className={cn(className)} {...props}>
      {children}
    </div>
  )
}

export function useDataTableController<TData, TValue>(
  columns: ColumnDef<TData, TValue>[],
  data: TData[],
  columnVisibility: VisibilityState = {},
): ReactTable<TData> {
  const [visibility, setVisibility] =
    useState<VisibilityState>(columnVisibility)
  const [sorting, setSorting] = useState<SortingState>([])
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  return useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnVisibilityChange: setVisibility,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getSortedRowModel: getSortedRowModel(),
    state: {
      columnVisibility: visibility,
      sorting,
      rowSelection,
    },
  })
}

export interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  table: ReactTable<TData>
}

export function DataTable<TData, TValue>({
  table,
  columns,
}: DataTableProps<TData, TValue>) {
  return (
    <div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map(cell => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-12 text-center"
                >
                  Keine Ergebnisse
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export interface DataTableBrowserPaginationProps<TData> {
  table: ReactTable<TData>
}

export function DataTableBrowserPagination<TData>({
  table,
}: DataTableBrowserPaginationProps<TData>) {
  return (
    <div className="grid grid-cols-3">
      <div className="flex w-[100px] items-center justify-center text-sm font-medium">
        Seite {table.getState().pagination.pageIndex + 1} {"von"}{" "}
        {table.getPageCount()}
      </div>
      <Pagination>
        <PaginationContent>
          <PaginationItem>
            <Button
              variant="ghost"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <ChevronLeft className="mr-2 h-4 w-4" />
              <span>Vorherige</span>
            </Button>
          </PaginationItem>
          {/* FIRST PAGE */}
          <PaginationItem>
            <Button
              variant={
                table.getState().pagination.pageIndex === 0
                  ? "outline"
                  : "ghost"
              }
              onClick={() => table.setPageIndex(0)}
            >
              {1}
            </Button>
          </PaginationItem>
          {/* MIDDLE PAGES */}
          {[
            ...Array(
              table.getPageCount() - 2 > 0 ? table.getPageCount() - 2 : 0,
            ).keys(),
          ].map(i => (
            <PaginationItem key={i + 1}>
              <Button
                variant={
                  table.getState().pagination.pageIndex === i + 1
                    ? "outline"
                    : "ghost"
                }
                onClick={() => table.setPageIndex(i + 1)}
              >
                {i + 2}
              </Button>
            </PaginationItem>
          ))}
          {/* LAST PAGE */}
          {table.getPageCount() > 1 && (
            <PaginationItem>
              <Button
                variant={
                  table.getState().pagination.pageIndex ===
                  table.getPageCount() - 1
                    ? "outline"
                    : "ghost"
                }
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              >
                {table.getPageCount()}
              </Button>
            </PaginationItem>
          )}
          <PaginationItem>
            <Button
              variant="ghost"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <span>Nächste</span>
              <ChevronRight className="ml-2 h-4 w-4" />
            </Button>
          </PaginationItem>
        </PaginationContent>
      </Pagination>
      <div className="inline-flex items-center justify-end space-x-2">
        <p className="text-sm font-medium">Zeilen pro Seite</p>
        <Select
          value={`${table.getState().pagination.pageSize}`}
          onValueChange={value => {
            table.setPageSize(Number(value))
          }}
        >
          <SelectTrigger className="h-8 w-[70px]">
            <SelectValue placeholder={table.getState().pagination.pageSize} />
          </SelectTrigger>
          <SelectContent side="top">
            {[10, 20, 30, 40, 50].map(pageSize => (
              <SelectItem key={pageSize} value={`${pageSize}`}>
                {pageSize}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  )
}
