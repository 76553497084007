import type {
  ColumnDef,
  Table as ReactTable,
  VisibilityState,
} from "@tanstack/react-table"
import { ReactElement } from "react"
import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/data-table"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import { SampleSetNumber } from "@/shared/components/sample-set/sample-set-number"
import {
  Incubation,
  Procedure,
} from "@/services/backend/procedures/procedure/procedure"
import { sampleTypes } from "@/shared/components/enum-labels"
import {
  GrowthMedium,
  ProcedureType,
  SampleType,
} from "@/services/backend/samples/sample-set/sample-set"
import { H4 } from "@/shared/components/typography"
import { useListProceduresQuery } from "@/services/backend/procedures/procedure/service"
import MeasurementLabelComponent from "@/routes/processes/[processId]/process/approval/_components/measurement-label-component"
import MouldResult from "@/shared/components/moulds/mould-result"
import { CollapsibleDataTable } from "@/shared/components/collapsible-data-table"
import DifferentiationMetadataRow from "../../differentiation/_components/differentiation-metadata-row"

const createTableColumns = (): ColumnDef<Procedure>[] => [
  {
    id: "sampleSet",
    accessorKey: "procedure",
    header: "Probenset",
    cell: ({ row }) => {
      const procedureData = row.original
      return <SampleSetNumber sampleSetId={procedureData.sampleSetId} />
    },
  },
  {
    id: "sampleType",
    accessorKey: "sampleType",
    header: "Probentyp",
    cell: ({ row }) => {
      const procedureData = row.original
      return <div>{sampleTypes[procedureData.sampleType]}</div>
    },
  },
  {
    id: "measurement-1",
    accessorKey: "incubation",
    header: "1. Auszählung",
    cell: ({ row }) => {
      const procedureData = row.original
      const incubation = procedureData.incubation.find(
        (value: Incubation) => value.growthMedium === GrowthMedium.Caso,
      )
      return incubation != null ? (
        <MeasurementLabelComponent
          incubation={incubation}
          sampleType={procedureData.sampleType as SampleType}
        />
      ) : (
        "Keine Probe gefunden"
      )
    },
  },
  {
    id: "measurement-2",
    accessorKey: "incubation",
    header: "2. Auszählung",
    cell: ({ row }) => {
      const procedureData = row.original
      const incubation = procedureData.incubation.find(
        (value: Incubation) => value.growthMedium === GrowthMedium.Malt,
      )
      return incubation != null ? (
        <MeasurementLabelComponent
          incubation={incubation}
          sampleType={procedureData.sampleType as SampleType}
        />
      ) : (
        "Keine Probe gefunden"
      )
    },
  },
  {
    id: "results",
    accessorKey: "results",
    header: "Ergebnisse",
    cell: ({ row }) => {
      const procedureData = row.original
      return (
        <div className="flex flex-col">
          <div className="flex flex-row space-x-4">
            <span>{procedureData.result.value}</span>
            <span>{procedureData.result.unit}</span>
          </div>
        </div>
      )
    },
  },
  {
    id: "mouldResult",
    accessorKey: "id",
    header: "Schimmelpilze",
    cell: ({ row }) => {
      const procedureData = row.original
      return (
        <div className="flex items-center space-x-2">
          <MouldResult sampleSetId={procedureData.sampleSetId} />
        </div>
      )
    },
  },
]

interface CalculationTableProps {
  processId: string
  procedureType: ProcedureType
  label: string
  visibilityState?: VisibilityState
}

function ApprovalTable({
  processId,
  procedureType,
  label,
  visibilityState = {},
}: CalculationTableProps): ReactElement {
  const {
    data: list,
    error,
    isLoading,
    isFetching,
  } = useListProceduresQuery(
    {
      processId: processId as string,
      procedureType: procedureType as ProcedureType,
      limit: 0,
      page: 0,
    },
    { refetchOnMountOrArgChange: true },
  )

  const cols = createTableColumns()
  const table = useDataTableController(cols, list?.data || [], visibilityState)

  if (isLoading || isFetching) {
    return <Skeleton table={table} columns={cols} />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (list != null && list.data != null && list.data.length > 0) {
    return (
      <div className="flex w-full flex-col space-y-4">
        <H4>{label}</H4>
        <DataTableContainer className="w-full">
          <CollapsibleDataTable
            table={table}
            columns={cols}
            renderCollapsibleContent={procedure => (
              <DifferentiationMetadataRow procedure={procedure} />
            )}
            collapsibleTriggerId="sampleSet"
          />
        </DataTableContainer>
      </div>
    )
  }

  return <div />
}

function Skeleton<TData>(props: {
  table: ReactTable<TData>
  columns: ColumnDef<TData>[]
}) {
  const { table, columns } = props
  return (
    <DataTableContainer className="animate-pulse">
      <DataTable table={table} columns={columns} />
    </DataTableContainer>
  )
}

export default ApprovalTable
