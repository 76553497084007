import { backendApi } from "@/services/api"
import type { SampleError } from "@/services/backend/samples/sample-error/sample-error"

const sampleErrorApi = backendApi.injectEndpoints({
  endpoints: build => ({
    createError: build.mutation<SampleError, PutRequest>({
      query: request => ({
        url: `/samples/sample-error`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["SampleError"],
    }),
    listSampleErrors: build.query<List, ListRequest>({
      query: () => ({
        url: `/samples/sample-error/list`,
        method: "GET",
        params: {},
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "SampleError" as const,
                id,
              })),
              { type: "SampleError", id: "LIST" },
            ]
          : [{ type: "SampleError", id: "LIST" }],
    }),
  }),
})

export const { useCreateErrorMutation, useListSampleErrorsQuery } =
  sampleErrorApi

export interface PutRequest {
  id: string
  description: string
}

export interface ListRequest {}

export interface List {
  data: SampleError[]
}
