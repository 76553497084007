import { Outlet } from "react-router-dom"
import { KeyRound, Phone } from "lucide-react"
import {
  ProcessPage,
  ProcessPageMain,
  ProcessPageNavigation,
  ProcessPageNavLink,
  ProcessPageNavLinkGroup,
  ProcessPageNavLinkGroupTitle,
  ProcessPageNavLinkList,
} from "@/shared/layout/process-page"

export default function CustomerRoute() {
  return (
    <ProcessPage>
      <Navigation />
      <ProcessPageMain>
        <Outlet />
      </ProcessPageMain>
    </ProcessPage>
  )
}

function Navigation() {
  return (
    <ProcessPageNavigation>
      <ProcessPageNavLinkGroup>
        <ProcessPageNavLinkGroupTitle>Allgemein</ProcessPageNavLinkGroupTitle>
        <ProcessPageNavLinkList>
          <ProcessPageNavLink to="">
            <Phone className="mr-2 h-5 w-5" />
            Ansprechpartner
          </ProcessPageNavLink>
        </ProcessPageNavLinkList>
      </ProcessPageNavLinkGroup>
      <ProcessPageNavLinkGroup>
        <ProcessPageNavLinkGroupTitle>
          Einstellungen
        </ProcessPageNavLinkGroupTitle>
        <ProcessPageNavLink to="api-key">
          <KeyRound className="mr-2 h-5 w-5" />
          API-Keys
        </ProcessPageNavLink>
      </ProcessPageNavLinkGroup>
    </ProcessPageNavigation>
  )
}
