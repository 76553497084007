import { RouteTitle } from "@/shared/components/titles"
import { CustomersListTable } from "./_components/customer-list-table"

export default function CustomersRoute() {
  return (
    <div className="mx-2 my-4 space-y-2">
      <RouteTitle>Kunden</RouteTitle>
      <CustomersListTable />
    </div>
  )
}
