import type { HTMLAttributes } from "react"
import { parseRTKQueryError } from "@/shared/components/errors/parse-r-t-k-query-error"
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/shared/shadcn/components/ui/alert"

interface RTKQueryErrorAlertProps extends HTMLAttributes<HTMLDivElement> {
  error: unknown
}

export function RTKQueryErrorAlert({
  error,
  className,
  ...props
}: RTKQueryErrorAlertProps) {
  if (error === undefined || error === null) {
    return null
  }

  // default values
  let title = "Fehler"
  let description = "Ein Fehler ist aufgetreten."

  const err = parseRTKQueryError(error)
  if (err == null) {
    return null
  }
  title = err.title
  description = err.description

  return (
    <Alert variant="destructive" className={className} {...props}>
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{description}</AlertDescription>
    </Alert>
  )
}
