import { RouteTitle } from "@/shared/components/titles"
import { DeliveriesListTable } from "./_components/deliveries-list-table"

export default function DeliveriesRoute() {
  return (
    <div className="mx-2 my-4 space-y-2">
      <RouteTitle>Paketübersicht</RouteTitle>
      <DeliveriesListTable />
    </div>
  )
}
