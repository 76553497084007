import { Outlet } from "react-router-dom"
import {
  Binoculars,
  FlaskRound,
  Phone,
  Shapes,
  Signature,
  TestTubes,
} from "lucide-react"
import {
  ProcessPage,
  ProcessPageMain,
  ProcessPageNavigation,
  ProcessPageNavLink,
  ProcessPageNavLinkGroup,
  ProcessPageNavLinkGroupTitle,
  ProcessPageNavLinkList,
} from "@/shared/layout/process-page"

export default function ProcessRoute() {
  return (
    <ProcessPage>
      <Navigation />
      <ProcessPageMain>
        <Outlet />
      </ProcessPageMain>
    </ProcessPage>
  )
}

function Navigation() {
  return (
    <ProcessPageNavigation>
      <ProcessPageNavLinkGroup>
        <ProcessPageNavLinkGroupTitle>Allgemein</ProcessPageNavLinkGroupTitle>
        <ProcessPageNavLinkList>
          {/* <ProcessPageNavLink to="" end>
            <LayoutDashboard className="mr-2 h-5 w-5" />
            Übersicht
          </ProcessPageNavLink> */}
          <ProcessPageNavLink to="contact">
            <Phone className="mr-2 h-5 w-5" />
            Ansprechpartner
          </ProcessPageNavLink>
        </ProcessPageNavLinkList>
      </ProcessPageNavLinkGroup>
      <ProcessPageNavLinkGroup>
        <ProcessPageNavLinkGroupTitle>
          Meilensteine
        </ProcessPageNavLinkGroupTitle>
        <ProcessPageNavLink to="documentation">
          <FlaskRound className="mr-2 h-5 w-5" />
          Probendokumentation
        </ProcessPageNavLink>
        <ProcessPageNavLink to="set-overview">
          <TestTubes className="mr-2 h-5 w-5" />
          Probensetübersicht
        </ProcessPageNavLink>
        <ProcessPageNavLink to="analytics">
          <Binoculars className="mr-2 h-5 w-5" />
          Zählung
        </ProcessPageNavLink>
        <ProcessPageNavLink to="differentiation">
          <Shapes className="mr-2 h-5 w-5" />
          Differenzierung
        </ProcessPageNavLink>
        <ProcessPageNavLink to="approval">
          <Signature className="mr-2 h-5 w-5" />
          Freigabe
        </ProcessPageNavLink>
      </ProcessPageNavLinkGroup>
    </ProcessPageNavigation>
  )
}
