import type { ColumnDef, Table as ReactTable } from "@tanstack/react-table"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/data-table"
import { AirSampler } from "@/services/backend/airsamplers/air-sampler"
import { useListAirSamplersQuery } from "@/services/backend/airsamplers/service"

const tableColumns: ColumnDef<AirSampler>[] = [
  {
    id: "description",
    accessorKey: "description",
    header: "Beschreibung",
    cell: ({ row }) => {
      const airSamplerData = row.original
      return <div>{airSamplerData.description}</div>
    },
  },
  {
    id: "volume",
    accessorKey: "volume",
    header: "Volumen",
    cell: ({ row }) => {
      const airSamplerData = row.original
      return <div>{airSamplerData.volume} Liter</div>
    },
  },
  {
    id: "holeCount",
    accessorKey: "holeCount",
    header: "Lochzahl",
    cell: ({ row }) => {
      const airSamplerData = row.original
      return <div>{airSamplerData.holeCount}</div>
    },
  },
]

export function AirSamplersTable() {
  const {
    data: list,
    isLoading,
    isFetching,
    error,
  } = useListAirSamplersQuery(
    { limit: 0, offset: 0 },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const table = useDataTableController(tableColumns, list?.items || [])

  if (isLoading || isFetching) {
    return <Skeleton table={table} columns={tableColumns} />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <DataTableContainer>
      <DataTable table={table} columns={tableColumns} />
    </DataTableContainer>
  )
}

function Skeleton<TData>(props: {
  table: ReactTable<TData>
  columns: ColumnDef<TData>[]
}) {
  const { table, columns } = props
  return (
    <DataTableContainer className="animate-pulse">
      <DataTable table={table} columns={columns} />
    </DataTableContainer>
  )
}
