import { ReactElement, useState } from "react"
import {
  Incubation,
  Measurement,
} from "@/services/backend/procedures/procedure/procedure"
import { Input } from "@/shared/shadcn/components/ui/input"
import { v4 as uuidv4 } from "uuid"
import { useAuth } from "@/shared/lib/authorization/auth-context"
import { format, differenceInHours, addHours, isAfter } from "date-fns"
import MetadataTooltip from "@/routes/processes/[processId]/process/analytics/_components/metadata-tooltip"
import { useGetProcessQuery } from "@/services/backend/processes/process/service"
import { useParams } from "react-router-dom"
import { skipToken } from "@reduxjs/toolkit/query"
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce"
import {
  AddMeasurementRequest,
  useAddMeasurementMutation,
} from "@/services/backend/procedures/procedure/service"
import { GrowthMedium } from "@/services/backend/samples/sample-set/sample-set"

interface MeasurementInputProps {
  procedureId: string
  incubation: Incubation
  incubationStart: string
}

const getTimeDifferenceToNow = (
  incubationStart: string,
  incubationOffset: number,
): number => {
  const now = new Date()
  let incubationDate = new Date(incubationStart)
  incubationDate = addHours(incubationDate, incubationOffset)

  return differenceInHours(now.toISOString(), incubationDate)
}

const checkIfMeasurementIsAllowed = (
  incubation: Incubation,
  incubationStart: string,
): boolean => {
  const now = new Date()
  const beforeTimestamp = addHours(
    incubationStart,
    incubation.minIncubationTime,
  )

  // const afterTimestamp = addHours(incubationStart, incubation.maxIncubationTime)

  return isAfter(now, beforeTimestamp)
}

const getMeasurementMetaData = (
  currentIncubation: Incubation,
  sequenceNumber: number,
): ReactElement => {
  if (currentIncubation.measurements.length > 0) {
    const selectedMeasurement = currentIncubation.measurements.find(
      currentMeasurement =>
        currentMeasurement.sequenceNumber === sequenceNumber,
    )

    if (selectedMeasurement === undefined) {
      return <div />
    }

    const formattedDate = format(
      selectedMeasurement.measurementDate,
      "dd.MM.yyyy",
    )
    const formattedTime = format(
      selectedMeasurement.measurementDate,
      "HH:mm:ss",
    )

    return selectedMeasurement ? (
      <MetadataTooltip
        userName="Tim Olbrich"
        date={formattedDate}
        time={formattedTime}
      />
    ) : (
      <div />
    )
  }

  return <div />
}

const getMeasurement = (
  currentIncubation: Incubation,
  sequenceNumber: number,
): Measurement | undefined => {
  if (currentIncubation.measurements.length > 0) {
    const selectedMeasurement = currentIncubation.measurements.find(
      currentMeasurement =>
        currentMeasurement.sequenceNumber === sequenceNumber,
    )
    return selectedMeasurement || undefined
  }

  return undefined
}

function MeasurementInput({
  incubation,
  procedureId,
  incubationStart,
}: MeasurementInputProps): ReactElement {
  const { user } = useAuth()
  const { processId } = useParams()
  const { data: process } = useGetProcessQuery(
    processId != null ? { id: processId } : skipToken,
  )
  const [request, setRequest] = useState<AddMeasurementRequest>({
    incubationId: incubation.id,
    procedureId,
    measurementId: uuidv4(),
    measurementTime: new Date().toISOString(),
    value: 0,
    employeeId: user ? user.id : uuidv4(),
    sequenceNumber: 0,
  })
  const [addMeasurement, { isLoading, error, isSuccess, reset }] =
    useAddMeasurementMutation()
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    addMeasurement,
    isLoading,
    error,
    isSuccess,
    reset,
    300,
    { toastError: true },
  )

  const valueChanged = (input: string, sequenceNumber: number) => {
    const numberInput = Number(input)
    const value = numberInput > 100 ? 100 : numberInput
    const upsertMeasurement = getMeasurement(incubation, sequenceNumber)
    const id = upsertMeasurement ? upsertMeasurement.id : uuidv4()
    setRequest({
      procedureId,
      employeeId: user?.id as string,
      measurementId: id,
      incubationId: incubation.id,
      value,
      measurementTime: new Date().toISOString(),
      sequenceNumber,
    })
  }

  return (
    <div className="flex flex-row items-center space-x-2">
      {checkIfMeasurementIsAllowed(incubation, incubationStart) ? (
        <div className="flex flex-col items-start space-y-1">
          <div className="flex flex-row items-center space-x-2">
            <Input
              defaultValue={getMeasurement(incubation, 1)?.measurement}
              className="w-40"
              type="number"
              min={0}
              max={100}
              onChange={e => {
                valueChanged(e.currentTarget.value, 1)
              }}
              disabled={
                (process != null ? process.completed : false) ||
                !checkIfMeasurementIsAllowed(incubation, incubationStart)
              }
            />
            {getMeasurementMetaData(incubation, 1)}
          </div>
          <p className="text-gray-700 text-muted-foreground">
            {incubation.growthMedium === GrowthMedium.Caso
              ? "Bakterien"
              : "Schimmelpilze / Hefen"}
          </p>
        </div>
      ) : (
        <p>
          Auszählung in{" "}
          {
            -getTimeDifferenceToNow(
              incubationStart,
              incubation.minIncubationTime,
            )
          }{" "}
          Stunden
        </p>
      )}

      {incubation.growthMediumCount > 1 &&
        checkIfMeasurementIsAllowed(incubation, incubationStart) && (
          <div className="flex flex-col items-start space-y-1">
            <div className="flex flex-row items-center space-x-2">
              <Input
                defaultValue={getMeasurement(incubation, 2)?.measurement}
                className="w-40"
                type="number"
                min={0}
                max={100}
                onChange={e => {
                  valueChanged(e.currentTarget.value, 2)
                }}
                disabled={process != null ? process.completed : false}
              />
              {getMeasurementMetaData(incubation, 2)}
            </div>
            <p className="text-gray-700 text-muted-foreground">Schimmelpilze</p>
          </div>
        )}
    </div>
  )
}

export default MeasurementInput
