import { backendApi } from "@/services/api"

const companyApi = backendApi.injectEndpoints({
  endpoints: build => ({
    putCompany: build.mutation<Company, PutCompanyRequest>({
      query: request => ({
        url: `/companies/company`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Company"],
    }),
    showCompany: build.query<Company, ShowCompanyRequest>({
      query: request => ({
        url: `/companies/company`,
        method: "GET",
        params: request,
      }),
      providesTags: result =>
        result ? [{ type: "Company", id: result.id }] : [],
    }),
    listCompany: build.query<CompanyList, ListCompanyRequest>({
      query: request => ({
        url: `/companies/company/list`,
        method: "GET",
        params: request,
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(company => ({
                type: "Company" as const,
                id: company.id,
              })),
              { type: "Company", id: "LIST" },
            ]
          : [{ type: "Company", id: "LIST" }],
    }),
    deleteCompany: build.mutation<Company, DeleteCompanyRequest>({
      query: request => ({
        url: `/companies/company`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: ["Company"],
    }),
  }),
})

export const {
  usePutCompanyMutation,
  useShowCompanyQuery,
  useListCompanyQuery,
  useDeleteCompanyMutation,
} = companyApi

export interface Company {
  id: string
  name: string
  shortName: string
  portalRoute: string
}

export interface CompanyList {
  data: Company[]
  count: number
}

export function sortCompanies(companies: Company[], sortBy: string) {
  const sortable: Company[] = []
  companies.forEach(company => {
    sortable.push(company)
  })
  const compareFn = (a: Company, b: Company) => {
    switch (sortBy) {
      case "name":
        return a.name.localeCompare(b.name)
      case "shortName":
        return a.shortName.localeCompare(b.shortName)
      default:
        return a.name.localeCompare(b.name)
    }
  }
  return sortable.sort(compareFn)
}

export interface PutCompanyRequest {
  id: string
  name: string
  shortName: string
}

export interface UpdatePortalRoute {
  id: string
  portalRoute: string
}

export interface DeleteCompanyRequest {
  id: string
}

export interface ShowCompanyRequest {
  id: string
}

export interface ListCompanyRequest {}
