import { useParams } from "react-router"
import { ProcedureType } from "@/services/backend/samples/sample-set/sample-set"
import DifferentiationTable from "./differentiation-table"

export function DifferentiationIndexRoute() {
  const { processId } = useParams()
  return (
    <div className="flex flex-col space-y-4">
      <DifferentiationTable
        label="2N-RLT"
        procedureType={ProcedureType.RLT2N}
        processId={processId as string}
      />
    </div>
  )
}
