import { ReactElement } from "react"
import {
  ProcedureType,
  SampleSet,
} from "@/services/backend/samples/sample-set/sample-set"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/shadcn/components/ui/select"
import { usePutSampleSetMutation } from "@/services/backend/samples/sample-set/service"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import { useParams } from "react-router-dom"
import { useGetProcessQuery } from "@/services/backend/processes/process/service"
import { skipToken } from "@reduxjs/toolkit/query"

interface ProcedureSelectorProps {
  sampleSet: SampleSet
}

function ProcedureSelector({
  sampleSet,
}: ProcedureSelectorProps): ReactElement {
  const [upsertSet, { error }] = usePutSampleSetMutation()
  const { processId } = useParams()
  const { data: process } = useGetProcessQuery(
    processId != null ? { id: processId } : skipToken,
  )

  const onChange = (updatedSet: SampleSet): void => {
    upsertSet({
      id: updatedSet.id,
      customerId: updatedSet.customerId,
      deliveryId: updatedSet.deliveryId,
      procedureType: updatedSet.procedureType,
      sampleType: updatedSet.sampleType,
    })
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <Select
      disabled={
        process != null
          ? process.completed || process.status === "IncubationStarted"
          : false
      }
      value={sampleSet.procedureType}
      onValueChange={(value: ProcedureType) =>
        onChange({ ...sampleSet, procedureType: value })
      }
    >
      <SelectTrigger className="w-24">
        <SelectValue placeholder="Theme" />
      </SelectTrigger>
      <SelectContent>
        {Object.values(ProcedureType).map(value => (
          <SelectItem key={value} value={value}>
            {value}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default ProcedureSelector
