import { useGetSampleSetQuery } from "@/services/backend/samples/sample-set/service"
import { RTKQueryErrorAlert } from "@/shared/components/errors/rtk-query-error-alert"
import { sampleTypes } from "../enum-labels"

interface SampleTypeComponentProps {
  sampleSetId: string
}

export function SampleTypeComponent({ sampleSetId }: SampleTypeComponentProps) {
  const { data, error } = useGetSampleSetQuery({ id: sampleSetId })

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (data != null) return <span>{sampleTypes[data.sampleType]}</span>

  return <span>Kein Probenset gefunden</span>
}
