import { createContext, useContext } from "react"
import { PersistenceState } from "@/shared/persistence-state/persistence-state"

interface PersistenceContextInterface {
  persistenceState: PersistenceState
  setPersistenceState: (state: PersistenceState) => void
}

export const PersistenceContext = createContext<PersistenceContextInterface>({
  persistenceState: PersistenceState.SAVED,
  setPersistenceState: () => {},
})

export function usePersistenceContext() {
  return useContext(PersistenceContext)
}
