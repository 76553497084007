import { useAuthEntraGroupListQuery } from "@/services/backend/entra/entragroup/service"
import { PutEntraGroupDialog } from "@/routes/base-data/entra/entra-groups/components/put-entra-group-dialog"
import { DeleteEntraGroupDialog } from "@/routes/base-data/entra/entra-groups/components/delete-entra-group-dialog"
import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/data-table"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import type { ColumnDef } from "@tanstack/react-table"
import type { Group } from "@/services/backend/entra/entragroup/group"
import { CompanyName } from "@/shared/components/company/company-name"

export function EntraGroupsTable() {
  const { data, isLoading, error } = useAuthEntraGroupListQuery({})

  const table = useDataTableController(columns, data?.data ?? [])

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (isLoading) {
    return (
      <DataTableContainer className="animate-pulse">
        <DataTable columns={columns} table={table} />
      </DataTableContainer>
    )
  }

  return (
    <DataTableContainer>
      <DataTable columns={columns} table={table} />
    </DataTableContainer>
  )
}

const columns: ColumnDef<Group>[] = [
  {
    header: "ID",
    accessorKey: "id",
  },
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Unternehmen",
    cell: ({ row }) => {
      const group = row.original
      return (
        <div className="flex flex-col">
          {group.companies.map(company => (
            <CompanyName companyId={company} key={company} />
          ))}
        </div>
      )
    },
  },
  {
    header: "Aktionen",
    cell: ({ row }) => {
      const group = row.original
      return (
        <span className="space-x-2">
          <PutEntraGroupDialog group={group} />
          <DeleteEntraGroupDialog group={group} />
        </span>
      )
    },
  },
]
