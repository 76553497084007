import { SampleSetTable } from "@/routes/processes/[processId]/process/set-overview/_components/sample-set-table"
import { useParams } from "react-router-dom"
import { SampleType } from "@/services/backend/samples/sample-set/sample-set"
import { PersistenceStateUsingContext } from "@/shared/persistence-state/persistence-state-info"

export function SetOverviewIndexRoute() {
  const { processId } = useParams()
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex w-full flex-row justify-end">
        <PersistenceStateUsingContext />
      </div>
      <SampleSetTable
        label="Oberflächenkeimproben"
        visibilityState={{ airSamplerHoleCount: false, airSampler: false }}
        processId={processId as string}
        sampleType={SampleType.SurfaceMicrobial}
      />
      <SampleSetTable
        label="Luftkeimproben"
        processId={processId as string}
        sampleType={SampleType.AirMicrobial}
      />
    </div>
  )
}
