import type { Group } from "@/services/backend/entra/entragroup/group"
import { backendApi } from "@/services/api"

const entraApi = backendApi.injectEndpoints({
  endpoints: build => ({
    authEntraGroupShow: build.query<Group, ShowRequest>({
      query: request => ({
        url: `auth/entra/group`,
        method: "GET",
        params: request,
      }),
      providesTags: (_r, _e, request) => [
        { type: "AuthEntraGroup", id: request.id },
      ],
    }),
    authEntraGroupList: build.query<List, ListRequest>({
      query: () => ({
        url: `auth/entra/group/list`,
        method: "GET",
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(group => ({
                type: "AuthEntraGroup" as const,
                id: group.id,
              })),
              {
                type: "AuthEntraGroup",
                id: "LIST",
              },
            ]
          : [{ type: "AuthEntraGroup", id: "LIST" }],
    }),
    authEntraGroupPut: build.mutation<Group, PutRequest>({
      query: request => ({
        url: `auth/entra/group`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["AuthEntraGroup"],
    }),
    authEntraGroupDelete: build.mutation<void, DeleteRequest>({
      query: request => ({
        url: `auth/entra/group`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: ["AuthEntraGroup"],
    }),
    authEntraGroupShowCompanies: build.query<
      ShowCompaniesResponse,
      ShowCompaniesRequest
    >({
      query: request => ({
        url: `auth/entra/group/companies`,
        method: "GET",
        params: request,
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useAuthEntraGroupShowQuery,
  useAuthEntraGroupListQuery,
  useAuthEntraGroupPutMutation,
  useAuthEntraGroupDeleteMutation,
  useAuthEntraGroupShowCompaniesQuery,
} = entraApi

export interface ShowRequest {
  id: string
}

export interface ListRequest {}

export interface List {
  data: Group[]
  count: number
}

export interface PutRequest extends Group {}

export interface DeleteRequest {
  id: string
}

export interface ShowCompaniesRequest {
  groupIds: string[]
}

export interface ShowCompaniesResponse {
  companyIds: string[]
}
