import type { ColumnDef, Table as ReactTable } from "@tanstack/react-table"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/data-table"
import type { SampleError } from "@/services/backend/samples/sample-error/sample-error"
import { useListSampleErrorsQuery } from "@/services/backend/samples/sample-error/service"

const tableColumns: ColumnDef<SampleError>[] = [
  {
    id: "Beschreibung",
    accessorKey: "description",
    header: "Beschreibung",
    cell: ({ row }) => {
      const sampleErrorData = row.original
      return <div>{sampleErrorData.description}</div>
    },
  },
]

export function SampleErrorListTable() {
  const {
    data: list,
    isLoading,
    isFetching,
    error,
  } = useListSampleErrorsQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const table = useDataTableController(tableColumns, list?.data || [])

  if (isLoading || isFetching) {
    return <Skeleton table={table} columns={tableColumns} />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <DataTableContainer>
      <DataTable table={table} columns={tableColumns} />
    </DataTableContainer>
  )
}

function Skeleton<TData>(props: {
  table: ReactTable<TData>
  columns: ColumnDef<TData>[]
}) {
  const { table, columns } = props
  return (
    <DataTableContainer className="animate-pulse">
      <DataTable table={table} columns={columns} />
    </DataTableContainer>
  )
}
