import type { NavLinkElement } from "@/shared/layout/navigation/main-nav"
import {
  Content,
  Logo,
  MainNav,
  UserMenu,
} from "@/shared/layout/navigation/main-nav"
import { Toaster } from "@/shared/shadcn/components/ui/toaster"

export default function Root() {
  const navList: NavLinkElement[] = [
    { to: "/deliveries", label: "Paketübersicht" },
    { to: "/processes", label: "Vorgänge" },
    { to: "/base-data", label: "Stammdaten" },
    { to: "/customers", label: "Kunden" },
  ]

  return (
    <div>
      <div className="fixed z-50 w-full border-b bg-background">
        <div className="mx-4 flex h-16 items-center">
          <Logo />
          <MainNav className="mx-6" navList={navList} />
          <div className="ml-auto flex items-center space-x-4">
            {/* <Search />
            <NotificationsMenu /> */}
            <UserMenu />
          </div>
        </div>
      </div>
      <div className="pt-16">
        <Content />
      </div>
      <Toaster />
    </div>
  )
}
