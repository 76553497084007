import { useGetCustomerQuery } from "@/services/backend/customers/customer/service"

export function CustomerName(props: { customerId: string }) {
  const { customerId } = props

  const {
    data: customer,
    isLoading,
    isError,
  } = useGetCustomerQuery({ id: customerId })
  if (isLoading) {
    return (
      <span className="animate-pulse">
        <span className="block h-2 rounded-full bg-gray-200" />
      </span>
    )
  }

  if (isError) {
    return <span>Fehler beim Laden des Kundennamen</span>
  }

  return <span>{customer?.name}</span>
}
