import { v4 } from "uuid"
import type { PropsWithChildren } from "react"
import { useMemo, useState } from "react"
import { Menu } from "@headlessui/react"
import type { AuthContextInterface } from "@/shared/lib/authorization/auth-context"
import { AuthContext } from "@/shared/lib/authorization/auth-context"
import type { User } from "./user"

const RoleAdmin = "Admin"
const RoleDirector = "Director"
const RoleBranchManager = "BranchManager"

/**
 * DevAuthContextProvider sets a default user in the AuthContext and
 * allows the developer to modify the role of the user for easier development.
 *
 * @param props
 * @constructor
 */
export function DevAuthContextProvider(props: PropsWithChildren) {
  const { children } = props
  const [user, setUser] = useState<User | null>({
    id: v4(),
    fullname: "Dev User",
    roles: [RoleAdmin],
    groups: [],
    initials: "DU",
  })

  const devAuthContext: AuthContextInterface = useMemo(
    () => ({
      user,
      login: (u: User) => setUser(u),
      logout: () => null,
    }),
    [user],
  )

  return (
    <AuthContext.Provider value={devAuthContext}>
      <div>{children}</div>
      <div className="absolute bottom-6 left-6">
        <DevRolePicker
          role={user?.roles[0] ?? ""}
          setRole={role =>
            setUser({
              id: user?.id ?? "",
              fullname: user?.fullname ?? "",
              roles: [role],
              groups: user?.groups ?? [],
              initials: user?.initials ?? "",
            })
          }
        />
      </div>
    </AuthContext.Provider>
  )
}

function DevRolePicker(props: {
  role: string
  setRole: (role: string) => void
}) {
  const { role, setRole } = props
  return (
    <Menu>
      <Menu.Button className="z-30 rounded-lg border bg-white px-3 py-2 text-sm shadow">
        Rolle: {role}
      </Menu.Button>
      <Menu.Items className="flex flex-col divide-y border bg-white py-2">
        <Menu.Item>
          {({ active }) => (
            <button
              type="button"
              className={`${active && "bg-blue-500"}`}
              onClick={() => setRole(RoleAdmin)}
            >
              Admin
            </button>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <button
              type="button"
              className={`${active && "bg-blue-500"}`}
              onClick={() => setRole(RoleDirector)}
            >
              Geschäftsführer
            </button>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <button
              type="button"
              className={`${active && "bg-blue-500"}`}
              onClick={() => setRole(RoleBranchManager)}
            >
              Niederlassungsleiter
            </button>
          )}
        </Menu.Item>
      </Menu.Items>
    </Menu>
  )
}
