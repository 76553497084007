import type { HTMLAttributes } from "react"
import AYSID from "@/assets/aysid_logo.png"
import { cn } from "@/shared/shadcn/lib/utils"
import { NavLink, Outlet } from "react-router-dom"
import { Input } from "@/shared/shadcn/components/ui/input"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/shadcn/components/ui/dropdown-menu"
import { Button } from "@/shared/shadcn/components/ui/button"
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/shared/shadcn/components/ui/avatar"
import { Bell } from "lucide-react"
import { Badge } from "@/shared/shadcn/components/ui/badge"
import { useAuth } from "@/shared/lib/authorization/auth-context"

export type NavLinkElement = {
  to: string
  label: string
}
type MainNavProps = {
  navList: NavLinkElement[]
} & HTMLAttributes<HTMLElement>

export function MainNav({ className, navList, ...props }: MainNavProps) {
  return (
    <nav
      className={cn("flex items-center space-x-4 lg:space-x-6", className)}
      {...props}
    >
      {navList.map(({ to, label }) => (
        <NavLink
          key={to}
          to={to}
          className={({ isActive }) =>
            isActive
              ? "text-sm font-medium transition-colors hover:text-primary"
              : "text-sm font-medium text-muted-foreground transition-colors hover:text-primary"
          }
        >
          {label}
        </NavLink>
      ))}
    </nav>
  )
}

export function Content() {
  return (
    <div className="m-2">
      <Outlet />
    </div>
  )
}

export function Logo() {
  return <img src={AYSID} alt="LOGO" className="h-8" />
}

export function Search() {
  return (
    <div>
      <Input
        type="search"
        placeholder="Suche"
        className="md:w-[150px] lg:w-[400px]"
      />
    </div>
  )
}

export function UserMenu() {
  const { user, logout } = useAuth()

  if (!user) {
    return null
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-full">
          <Avatar className="h-8 w-8">
            <AvatarImage src="" alt="" />
            <AvatarFallback>{user.initials}</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{user.fullname}</p>
            <p className="text-xs leading-none text-muted-foreground">
              {user.roles.join(", ")}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem>Einstellungen</DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={logout}>Log out</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export function NotificationsMenu() {
  const notifications = [
    {
      id: 1,
      title: "Neue Nachricht",
      description: "Du hast eine neue Nachricht von Max Mustermann erhalten.",
      date: "vor 2 Stunden",
    },
    {
      id: 2,
      title: "Neue Nachricht",
      description: "Du hast eine neue Nachricht von Max Mustermann erhalten.",
      date: "vor 2 Stunden",
    },
  ]

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-full">
          <Avatar className="h-8 w-8">
            <AvatarImage src="" alt="" />
            <AvatarFallback>
              <Bell className="h-5 w-5" />
            </AvatarFallback>
          </Avatar>
          {notifications.length > 0 ? (
            <Badge className="absolute -right-2 -top-2 h-5 w-5 items-center justify-center text-xs">
              {notifications.length}
            </Badge>
          ) : null}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuGroup>
          {notifications.map(notification => (
            <DropdownMenuItem key={notification.id} className="flex-col">
              <div className="w-full font-bold">{notification.title}</div>
              <div>{notification.description}</div>
              <div className="w-full text-right">{notification.date}</div>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem>Alle anzeigen</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
