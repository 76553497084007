import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { v4 as uuidv4 } from "uuid"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/shared/shadcn/components/ui/form"
import { Input } from "@/shared/shadcn/components/ui/input"
import { Button } from "@/shared/shadcn/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/shadcn/components/ui/dialog"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/shadcn/components/ui/select"
import { useCustomersListQuery } from "@/services/backend/customers/customer/service"
import { usePutProcessMutation } from "@/services/backend/processes/process/service"
import { useEffect, useState } from "react"

const FormSchema = z.object({
  topic: z.string(),
  processNumber: z.string(),
  customerId: z.string(),
})

export function CreateProcessForm() {
  const [open, setOpen] = useState(false)
  const [createProcess, { isSuccess, reset }] = usePutProcessMutation()
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  })

  const { data: customerList } = useCustomersListQuery({ offset: 0, limit: 50 })

  function onSubmit(data: z.infer<typeof FormSchema>) {
    createProcess({
      id: uuidv4(),
      customerId: data.customerId,
      topic: data.topic,
      processNumber: data.processNumber,
    })
  }

  useEffect(() => {
    setOpen(false)
    form.reset()
    reset()
  }, [isSuccess, reset, form])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Vorgang anlegen</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Vorgang anlegen</DialogTitle>
          <DialogDescription>
            Hier können Sie neue Vorgänge anlegen
          </DialogDescription>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="w-full space-y-6"
            >
              <FormField
                control={form.control}
                name="topic"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Thema</FormLabel>
                    <FormControl>
                      <Input placeholder="Analyse" {...field} />
                    </FormControl>
                    <FormDescription>
                      Genauere Beschreibung des Vorgangs
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="customerId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Kunde</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Einen Kunden auswählen" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {customerList != null &&
                          customerList.data != null &&
                          customerList.data.map(customer => (
                            <SelectItem key={customer.id} value={customer.id}>
                              {customer.name}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                    <FormDescription>
                      Der Kunde, dem der Vorgang zugeordnet werden soll.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="processNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Vorgangs-Nr.</FormLabel>
                    <FormControl>
                      <Input placeholder="Vorgangs-Nr." {...field} />
                    </FormControl>
                    <FormDescription>Kennung des Vorgangs</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit">Submit</Button>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  )
}
