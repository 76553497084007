import { useState, useEffect, useCallback } from "react"

import { HelpCircle } from "lucide-react"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shared/shadcn/components/ui/tooltip"
import { Button } from "@/shared/shadcn/components/ui/button"
import { Label } from "@/shared/shadcn/components/ui/label"

interface MetadataTooltipProps {
  userName: string
  date: string
  time: string
}

export default function MetadataTooltip({
  userName,
  date,
  time,
}: MetadataTooltipProps) {
  const [open, setOpen] = useState(false)
  const [isTouch, setIsTouch] = useState(false)
  const [isTablet, setIsTablet] = useState(false)

  useEffect(() => {
    setIsTouch("ontouchstart" in window || navigator.maxTouchPoints > 0)

    const mediaQuery = window.matchMedia(
      "(min-width: 768px) and (max-width: 1024px)",
    )
    setIsTablet(mediaQuery.matches)

    const handleMediaQueryChange = (e: MediaQueryListEvent) =>
      setIsTablet(e.matches)
    mediaQuery.addEventListener("change", handleMediaQueryChange)

    return () =>
      mediaQuery.removeEventListener("change", handleMediaQueryChange)
  }, [])

  const handleTooltipToggle = useCallback(() => {
    if (isTouch && isTablet) {
      setOpen(prev => !prev)
    }
  }, [isTouch, isTablet])

  const handleClickOutside = useCallback(() => {
    if (isTouch && isTablet && open) {
      setOpen(false)
    }
  }, [isTouch, isTablet, open])

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)
    return () => document.removeEventListener("click", handleClickOutside)
  }, [handleClickOutside])

  return (
    <TooltipProvider>
      <Tooltip
        open={isTouch && isTablet ? open : undefined}
        onOpenChange={setOpen}
        delayDuration={0}
      >
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className="focus:disabled h-8 w-8"
            onClick={e => {
              e.stopPropagation()
              handleTooltipToggle()
            }}
          >
            <HelpCircle className="h-4 w-4" />
            <span className="sr-only">Analytics Metadata</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent onClick={e => e.stopPropagation()}>
          <div className="flex flex-col">
            <Label className="text-sm font-medium text-muted-foreground">
              {userName}
            </Label>
            <Label className="text-sm font-medium text-muted-foreground">
              <div className="flex flex-col">
                <p>{date}</p>
                <p>{time}</p>
              </div>
            </Label>
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
