import { useState, useEffect, useMemo } from "react"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/shadcn/components/ui/dialog"
import { Button } from "@/shared/shadcn/components/ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/shadcn/components/ui/select"
import { Input } from "@/shared/shadcn/components/ui/input"
import { ScrollArea } from "@/shared/shadcn/components/ui/scroll-area"
import { Checkbox } from "@/shared/shadcn/components/ui/checkbox"
import {
  useAssignToBatchMutation,
  useListErrorAssignmentViewModelQuery,
} from "@/services/backend/samples/sample-set/sample-service"
import {
  ErrorAssignmentViewModel,
  GrowthMedium,
  SampleType,
} from "@/services/backend/samples/sample-set/sample-set"
import { LandPlot, Wind } from "lucide-react"
import { evaluableTypes, growthMedia } from "@/shared/components/enum-labels"
import { useListBatchesQuery } from "@/services/backend/batches/batch/service"
import { BatchComponent } from "@/shared/components/samples/batch-component"
import { FacetedOptions, FacetedFilter } from "./faceted-filter"

// Options for FacetedFilter SampleType
const sampleTypeOptions: FacetedOptions[] = [
  {
    label: "Luftkeim",
    value: SampleType.AirMicrobial,
    icon: Wind,
  },
  {
    label: "Oberflächenkeim",
    value: SampleType.SurfaceMicrobial,
    icon: LandPlot,
  },
]

// Options for FacetedFilter GrowthMedium
const growthMediumOptions: FacetedOptions[] = [
  {
    label: growthMedia[GrowthMedium.Caso],
    value: GrowthMedium.Caso,
  },
  {
    label: growthMedia[GrowthMedium.Malt],
    value: GrowthMedium.Malt,
  },
  {
    label: growthMedia[GrowthMedium.DG18],
    value: GrowthMedium.DG18,
  },
]

interface AssignToBatchDialogProps {
  processId: string
}

export default function AssignToBatchDialog({
  processId,
}: AssignToBatchDialogProps) {
  const [selectedValue, setSelectedValue] = useState<string | null>(null)
  const [assignToBatch] = useAssignToBatchMutation()
  const [entries, setEntries] = useState<ErrorAssignmentViewModel[]>([])
  const { data: samples } = useListErrorAssignmentViewModelQuery({
    processId,
    limit: 0,
    offset: 0,
  })

  const { data: batches } = useListBatchesQuery({ limit: 0, offset: 0 })

  useEffect(() => {
    if (samples != null && samples.data && samples.data.length > 0) {
      setEntries(samples.data)
    }
  }, [samples, setEntries])

  useEffect(() => {
    if (batches != null && batches.data && batches.data.length > 0) {
      setSelectedValue(batches.data[0].id)
    }
  }, [batches])

  useEffect(() => {
    if (
      samples != null &&
      samples.data &&
      samples.data.length > 0 &&
      selectedValue !== ""
    ) {
      const selectedEntries: ErrorAssignmentViewModel[] = []
      samples.data.forEach(sample => {
        if (sample.batchId === selectedValue) selectedEntries.push(sample)
      })
      setSelectedEntries(selectedEntries)
    }
  }, [samples, selectedValue])

  const [selectedEntries, setSelectedEntries] = useState<
    ErrorAssignmentViewModel[]
  >([])
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedSampleTypes, setSelectedSampleTypes] = useState<string[]>([])
  const [selectedGrowthMedia, setSelectedGrowthMedia] = useState<string[]>([])
  const filteredEntries = useMemo(() => {
    let filtered = entries.filter(entry =>
      entry.sampleSetNumber.toLowerCase().includes(searchTerm.toLowerCase()),
    )

    if (selectedGrowthMedia.length > 0) {
      filtered = filtered.filter(entry =>
        selectedGrowthMedia.includes(entry.growthMedium),
      )
    }

    if (selectedSampleTypes.length > 0) {
      filtered = filtered.filter(entry =>
        selectedSampleTypes.includes(entry.sampleType),
      )
    }

    return filtered
  }, [entries, searchTerm, selectedGrowthMedia, selectedSampleTypes])

  const handleValueAssignment = () => {
    if (selectedValue) {
      const checkedSamples = selectedEntries.map(entry => entry.id)
      assignToBatch({ batchId: selectedValue, sampleIds: checkedSamples })
      setSelectedEntries([])
    }
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">Chargenzuweisung</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Chargenzuweisung</DialogTitle>
          <DialogDescription>
            Weisen Sie den Proben eine Charge zu
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-2 py-4">
          <div className="w-full items-center space-y-2">
            <h2 className="font-bold">Chargen</h2>
            {batches?.data != null && batches.data.length > 0 && (
              <Select
                defaultValue={selectedValue || ""}
                onValueChange={(value: string) => setSelectedValue(value)}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Wählen Sie eine Charge aus." />
                </SelectTrigger>
                <SelectContent>
                  {batches.data.map(value => (
                    <SelectItem key={value.id} value={value.id}>
                      <BatchComponent batchId={value.id} />
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          </div>

          <div className="mt-4 space-y-2">
            <h2 className="font-bold">Proben</h2>
            <Input
              placeholder="Proben suchen..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              className="mb-2"
            />
            <FacetedFilter
              title="Probentyp"
              options={sampleTypeOptions}
              selectedValues={selectedSampleTypes}
              setSelectedValues={setSelectedSampleTypes}
            />
            <FacetedFilter
              title="Nährmedium"
              options={growthMediumOptions}
              selectedValues={selectedGrowthMedia}
              setSelectedValues={setSelectedGrowthMedia}
            />

            <ScrollArea className="h-[200px] w-full rounded-md border p-4">
              {filteredEntries.map(entry => (
                <div
                  key={entry.id}
                  className="mb-2 flex items-center space-x-2"
                >
                  <Checkbox
                    id={`entry-${entry.id}`}
                    checked={selectedEntries.some(
                      sample => sample.id === entry.id,
                    )}
                    onCheckedChange={checked => {
                      setSelectedEntries(prev =>
                        checked
                          ? [...prev, entry]
                          : prev.filter(sample => sample.id !== entry.id),
                      )
                    }}
                  />
                  <label htmlFor={`entry-${entry.id}`}>
                    {entry.sampleSetNumber} ({growthMedia[entry.growthMedium]})
                    - {evaluableTypes[entry.evaluableType]}
                  </label>
                </div>
              ))}
            </ScrollArea>

            <div className="mb-8 flex items-center justify-end space-x-2">
              <Checkbox
                id="select-all"
                checked={
                  selectedEntries.length === filteredEntries.length &&
                  filteredEntries.length > 0
                }
                onCheckedChange={checked =>
                  checked
                    ? setSelectedEntries(filteredEntries)
                    : setSelectedEntries([])
                }
                aria-labelledby="select-all-label" // Optional for better accessibility
              />
              <span>Alle auswählen</span>
            </div>
          </div>
          <Button
            onClick={handleValueAssignment}
            disabled={!selectedValue || selectedEntries.length === 0}
          >
            Charge zuweisen
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
