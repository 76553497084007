import { useGetMouldQuery } from "@/services/backend/mould/service"
import { RTKQueryErrorAlert } from "@/shared/components/errors/rtk-query-error-alert"

interface MouldLabelProps {
  id: string
}

export function MouldLabel({ id }: MouldLabelProps) {
  const { data, error } = useGetMouldQuery({ id })

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (data != null) return <span>{data.description}</span>

  return <span>Keinen Schimmelpilz gefunden</span>
}
