import { createContext, useContext } from "react"
import type { User } from "@/shared/lib/authorization/user"

/**
 * useAuth allows clients to access the AuthContext
 */
export const useAuth = () => useContext(AuthContext)

export const AuthContext = createContext<AuthContextInterface>({
  user: null,
  logout: () => {},
  login: () => {},
})

export interface AuthContextInterface {
  user: User | null
  logout: () => void
  login: (user: User) => void
}
