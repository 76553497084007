import { PropsWithChildren, useMemo, useState } from "react"
import { PersistenceState } from "@/shared/persistence-state/persistence-state"
import { PersistenceContext } from "./context"

export function PersistenceContextProvider(props: PropsWithChildren) {
  const { children } = props
  const [state, setState] = useState(PersistenceState.SAVED)

  const value = useMemo(
    () => ({
      persistenceState: state,
      setPersistenceState: setState,
    }),
    [state],
  )

  return (
    <PersistenceContext.Provider value={value}>
      {children}
    </PersistenceContext.Provider>
  )
}
