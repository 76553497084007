import { Outlet } from "react-router-dom"
import { LayoutDashboard, Phone } from "lucide-react"
import {
  ProcessPage,
  ProcessPageMain,
  ProcessPageNavigation,
  ProcessPageNavLink,
  ProcessPageNavLinkGroup,
  ProcessPageNavLinkGroupTitle,
  ProcessPageNavLinkList,
} from "@/shared/layout/process-page"

export default function DeliveryRoute() {
  return (
    <ProcessPage>
      <Navigation />
      <ProcessPageMain>
        <Outlet />
      </ProcessPageMain>
    </ProcessPage>
  )
}

function Navigation() {
  return (
    <ProcessPageNavigation>
      <ProcessPageNavLinkGroup>
        <ProcessPageNavLinkGroupTitle>Allgemein</ProcessPageNavLinkGroupTitle>
        <ProcessPageNavLinkList>
          <ProcessPageNavLink to="" end>
            <LayoutDashboard className="mr-2 h-5 w-5" />
            Übersicht
          </ProcessPageNavLink>
          <ProcessPageNavLink to="contact">
            <Phone className="mr-2 h-5 w-5" />
            Ansprechpartner
          </ProcessPageNavLink>
        </ProcessPageNavLinkList>
      </ProcessPageNavLinkGroup>
    </ProcessPageNavigation>
  )
}
