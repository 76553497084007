import { backendApi } from "@/services/api"
import { Contact } from "./contact"

const contactsApi = backendApi.injectEndpoints({
  endpoints: build => ({
    contactsList: build.query<List, ListRequest>({
      query: request => ({
        url: `/customers/contacts/list`,
        method: "GET",
        params: {
          customerId: request.customerId,
          limit: request.limit,
          offset: request.offset,
        },
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Contact" as const,
                id,
              })),
              { type: "Contact", id: "LIST" },
            ]
          : [{ type: "Contact", id: "LIST" }],
    }),
    getContact: build.query<Contact, GetRequest>({
      query: request => ({
        url: `/customers/contacts`,
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _e, request) => [
        { type: "Contact", id: request.id },
      ],
    }),
    createContact: build.mutation<Contact, PutRequest>({
      query: request => ({
        url: `/customers/contacts`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Contact"],
    }),
  }),
})

export const {
  useCreateContactMutation,
  useGetContactQuery,
  useContactsListQuery,
} = contactsApi

export interface PutRequest {
  id: string
  customerId: string
  salutation: string
  title: string
  name: string
  function: string
  email: string
  phone: string
  mobilephone: string
}

export interface GetRequest {
  id: string
}

export interface ListRequest {
  customerId: string
  limit: number
  offset: number
}

export interface List {
  count: number
  data: Contact[]
}
