import { backendApi } from "@/services/api"
import { ProcedureType } from "@/services/backend/samples/sample-set/sample-set"
import { Procedure } from "@/services/backend/procedures/procedure/procedure"

const procedureApi = backendApi.injectEndpoints({
  endpoints: build => ({
    startProcedure: build.mutation<
      StartProcedureResponse,
      StartProcedureRequest
    >({
      query: request => ({
        url: `/samples/procedures`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["Procedures"],
    }),
    putProcedure: build.mutation<Procedure, PutRequest>({
      query: request => ({
        url: `/samples/procedures`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Procedures"],
    }),
    calculate: build.mutation<CalculateResponse, CalculateRequest>({
      query: request => ({
        url: `/samples/procedures/calculate`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["Procedures"],
    }),
    listProcedures: build.query<List, ListProceduresRequest>({
      query: request => ({
        url: `/samples/procedures/list`,
        method: "GET",
        params: {
          processId: request.processId,
          procedureType: request.procedureType,
          limit: request.limit,
          page: request.page,
        },
      }),
      providesTags: ["Procedures"],
    }),
    addMeasurement: build.mutation<
      AddMeasurementResponse,
      AddMeasurementRequest
    >({
      query: request => ({
        url: `/samples/procedures/incubations/measurements`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Procedures"],
    }),
  }),
})

export const {
  usePutProcedureMutation,
  useStartProcedureMutation,
  useListProceduresQuery,
  useCalculateMutation,
  useAddMeasurementMutation,
} = procedureApi

interface StartProcedureRequest {
  processId: string
}

export interface PutRequest {
  id: string
  sampleSetId: string
  processId: string
  description?: string
  comment?: string
  holeCount?: number
}

interface StartProcedureResponse {}

interface ListProceduresRequest {
  limit: number
  page: number
  procedureType: ProcedureType
  processId: string
}

interface List {
  count: number
  data: Procedure[]
}

export interface CalculateRequest {
  processId: string
}

export interface CalculateResponse {}

export interface AddMeasurementRequest {
  incubationId: string
  procedureId: string
  measurementId: string
  measurementTime: string
  value: number
  employeeId: string
  sequenceNumber: number
}

interface AddMeasurementResponse {}
