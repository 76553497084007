export enum State {
  Announced = "announced",
  InDelivery = "inDelivery",
  Delivered = "delivered",
}

export enum Condition {
  Fine = "fine",
  Damaged = "damaged",
  Rejected = "rejected",
}
