import { Procedure } from "@/services/backend/procedures/procedure/procedure"
import { useGetSampleSetQuery } from "@/services/backend/samples/sample-set/service"
import { RTKQueryErrorAlert } from "@/shared/components/errors/rtk-query-error-alert"
import { MouldLabel } from "@/shared/components/moulds/mould-label"
import { Badge } from "@/shared/shadcn/components/ui/badge"
import { Card } from "@/shared/shadcn/components/ui/card"
import { ReactElement } from "react"

interface DifferentiationMetadataRowProps {
  procedure: Procedure
}

function DifferentiationMetadataRow({
  procedure,
}: DifferentiationMetadataRowProps): ReactElement {
  const { data: sampleSet, error } = useGetSampleSetQuery({
    id: procedure.sampleSetId,
  })

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (
    sampleSet != null &&
    sampleSet.mouldDifferentiation != null &&
    sampleSet.mouldDifferentiation.length === 0
  ) {
    return <p>Keine Pilze vorhanden</p>
  }

  return (
    <div className="flex flex-col space-y-4">
      {sampleSet?.mouldDifferentiation.map(mould => (
        <Card className="flex flex-col space-y-2 p-4">
          <div className="flex flex-row space-x-1">
            <p className="font-bold">Art: </p>
            <MouldLabel id={mould.mouldId} />
          </div>
          <div className="flex flex-row space-x-1">
            <p className="font-bold">Zählung: </p>
            <p>{mould.value}</p>
          </div>
          <div className="flex flex-row space-x-1">
            <p className="font-bold">Überwachsen: </p>
            <div>
              {mould.overgrown ? (
                <Badge className="bg-red-700 text-white">Ja</Badge>
              ) : (
                <Badge className="bg-green-700 text-white">Nein</Badge>
              )}
            </div>
          </div>
        </Card>
      ))}
    </div>
  )
}

export default DifferentiationMetadataRow
