import { H2 } from "@/shared/components/typography"
import PutBatchDialog from "@/routes/base-data/sample/batches/_components/put-batch-dialog"
import { BatchListTable } from "@/routes/base-data/sample/batches/_components/batch-list-table"

export function BatchesRoute() {
  return (
    <div>
      <H2 className="flex w-full justify-between">
        <span>Chargen</span>
        <PutBatchDialog />
      </H2>
      <div className="pt-4 text-muted-foreground">
        Chargen die verwendet werden.
      </div>
      <div className="pt-4">
        <BatchListTable />
      </div>
    </div>
  )
}
