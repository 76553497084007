import { ReactElement, useState } from "react"
import { SampleSet } from "@/services/backend/samples/sample-set/sample-set"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/shadcn/components/ui/select"
import {
  PutRequest,
  usePutSampleSetMutation,
} from "@/services/backend/samples/sample-set/service"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import { useListAirSamplersQuery } from "@/services/backend/airsamplers/service"
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce"
import { useParams } from "react-router"
import { useGetProcessQuery } from "@/services/backend/processes/process/service"
import { skipToken } from "@reduxjs/toolkit/query"

interface AirSamplerSelectorProps {
  sampleSet: SampleSet
}

function AirSamplerSelector({
  sampleSet,
}: AirSamplerSelectorProps): ReactElement {
  const { data, error: airSamplerError } = useListAirSamplersQuery({
    offset: 0,
    limit: 0,
  })

  const { processId } = useParams()
  const { data: process } = useGetProcessQuery(
    processId != null ? { id: processId } : skipToken,
  )
  const [request, setRequest] = useState<PutRequest>({
    id: sampleSet.id,
    customerId: sampleSet.customerId,
    deliveryId: sampleSet.deliveryId,
    procedureType: sampleSet.procedureType,
    airSamplerId: "",
  })

  const [upsertSet, { error, isLoading, isSuccess, reset }] =
    usePutSampleSetMutation()
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    upsertSet,
    isLoading,
    error,
    isSuccess,
    reset,
    300,
    { toastError: true },
  )

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (airSamplerError) {
    return <RTKQueryErrorAlert error={airSamplerError} />
  }

  if (!data || data.items.length === 0) {
    return <div>Keine Luftkeimsammler angelegt</div>
  }

  return (
    <Select
      disabled={
        process != null
          ? process.completed || process.status === "IncubationStarted"
          : false
      }
      value={sampleSet.airSamplerId}
      onValueChange={(value: string) =>
        setRequest({ ...sampleSet, airSamplerId: value })
      }
    >
      <SelectTrigger>
        <SelectValue placeholder="Luftkeimsammler" />
      </SelectTrigger>
      <SelectContent>
        {data.items.map(value => (
          <SelectItem key={value.id} value={value.id}>
            {value.description}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default AirSamplerSelector
