import { useParams } from "react-router-dom"
import { H2, P } from "@/shared/components/typography"
import { useContactsListQuery } from "@/services/backend/contacts/contact/service"
import { Contact } from "@/services/backend/contacts/contact/contact"
import { RTKQueryErrorAlert } from "@/shared/components/errors/rtk-query-error-alert"
import { CreateContactDialog } from "./_components/create-contact-dialog"

export default function ContactRoute() {
  const { customerId } = useParams()

  const { data: contacts, error } = useContactsListQuery(
    { customerId: customerId as string, limit: 0, offset: 0 },
    { skip: !customerId },
  )

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <div className="space-y-4">
      <H2>Ansprechpartner</H2>
      <P className="text-muted-foreground">
        Hier werden alle Ansprechpartner angezeigt welche mit dem Vorgang oder
        dem Kunden verbunden sind.
      </P>
      {customerId != null && <CreateContactDialog customerId={customerId} />}
      <div className="grid w-fit grid-cols-1 gap-4 lg:grid-cols-2">
        {contacts?.data?.map((contact: Contact) => (
          <div
            className="block max-w-sm rounded-lg border border-gray-200 bg-white p-4 shadow sm:max-w-md"
            key={contact.id}
          >
            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
              {contact.name}
            </h5>
            <div className="flex flex-row justify-between">
              <span className="text-sm text-gray-600">Funktion</span>
              <span className="text-right text-sm font-medium text-gray-700">
                {contact.function}
              </span>
            </div>
            <div className="flex flex-row justify-between">
              <span className="text-sm text-gray-600">Telefon</span>
              <a
                href={`tel:${contact.phone}`}
                className="text-right text-sm font-medium text-blue-700 hover:underline"
              >
                {contact.phone}
              </a>
            </div>
            <div className="flex flex-row justify-between">
              <span className="text-sm text-gray-600">Mobil</span>
              <a
                href={`tel:${contact.mobilephone}`}
                className="text-right text-sm font-medium text-blue-700 hover:underline"
              >
                {contact.mobilephone}
              </a>
            </div>
            <div className="flex flex-row justify-between space-x-3">
              <span className="text-sm text-gray-600">E-Mail</span>
              <a
                href={`tel:${contact.email}`}
                className="text-right text-sm font-medium text-blue-700 hover:underline"
              >
                {contact.email}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
