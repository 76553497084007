import { backendApi } from "@/services/api"
import type { Customer } from "./customer"

const customersApi = backendApi.injectEndpoints({
  endpoints: build => ({
    customersList: build.query<List, ListRequest>({
      query: request => ({
        url: `/customers/list`,
        method: "GET",
        params: {
          limit: request.limit,
          offset: request.offset,
        },
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Customer" as const,
                id,
              })),
              { type: "Customer", id: "LIST" },
            ]
          : [{ type: "Customer", id: "LIST" }],
    }),
    getCustomer: build.query<Customer, GetRequest>({
      query: request => ({
        url: `/customers`,
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _e, request) => [
        { type: "Customer", id: request.id },
      ],
    }),
    createCustomer: build.mutation<Customer, PutRequest>({
      query: request => ({
        url: `/customers`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Customer"],
    }),
  }),
})

export const {
  useCustomersListQuery,
  useGetCustomerQuery,
  useCreateCustomerMutation,
} = customersApi

export interface PutRequest {
  id: string
  name: string
}

export interface GetRequest {
  id: string
}

export interface ListRequest {
  limit: number
  offset: number
}

export interface List {
  count: number
  data: Customer[]
}
