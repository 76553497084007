import type { HTMLAttributes, ReactNode } from "react"
import { CalendarDays, PackageCheck, Truck } from "lucide-react"
import { State } from "@/services/backend/deliveries/state/state"
import { cn } from "@/shared/shadcn/lib/utils"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shared/shadcn/components/ui/tooltip"

interface StateIconProps extends HTMLAttributes<HTMLDivElement> {
  label?: State
}

export function StateIcon({ label, className }: StateIconProps) {
  if (label === undefined) {
    return null
  }

  const icons: { [key: string]: ReactNode } = {
    [State.Announced]: <CalendarDays className="h-4 w-4" />,
    [State.InDelivery]: <Truck className="h-4 w-4" />,
    [State.Delivered]: <PackageCheck className="h-4 w-4" />,
  }

  return (
    <div className={cn("flex", className)}>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>{icons[label]}</TooltipTrigger>
          <TooltipContent>{label}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  )
}

StateIcon.defaultProps = {
  label: undefined,
}
