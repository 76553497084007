import { backendApi } from "@/services/api"
import { AirSampler } from "./air-sampler"

const airSamplersApi = backendApi.injectEndpoints({
  endpoints: build => ({
    listAirSamplers: build.query<List, ListRequest>({
      query: request => ({
        url: `/samples/airsamplers/list`,
        method: "GET",
        params: {
          limit: request.limit,
          offset: request.offset,
        },
      }),
      providesTags: ["AirSamplers"],
    }),
    getAirSampler: build.query<AirSamplerResponse, GetRequest>({
      query: request => ({
        url: `/samples/airsamplers`,
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _e, request) => [
        { type: "AirSamplers", id: request.id },
      ],
    }),
    putAirSampler: build.mutation<AirSamplerResponse, PutRequest>({
      query: request => ({
        url: `/samples/airsamplers`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["AirSamplers"],
    }),
  }),
})

export const {
  useListAirSamplersQuery,
  useGetAirSamplerQuery,
  usePutAirSamplerMutation,
} = airSamplersApi

export interface PutRequest {
  id: string
  description: string
  volume: number
  holeCount: number
}

export interface GetRequest {
  id: string
}

export interface AirSamplerResponse {
  airSampler: AirSampler
}

export interface ListRequest {
  limit: number
  offset: number
}

export interface List {
  count: number
  items: AirSampler[]
}
