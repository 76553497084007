import type { RouteObject } from "react-router-dom"
import ContactRoute from "@/routes/customers/[customerId]/contacts/contact"
import ApiKey from "@/routes/customers/[customerId]/api-key/api-key"

export const customerRoutes: RouteObject[] = [
  {
    index: true,
    element: <ContactRoute />,
  },
  {
    path: "api-key",
    element: <ApiKey />,
  },
]
