import { SampleError } from "@/services/backend/samples/sample-error/sample-error"

export enum GrowthMedium {
  Caso = "Caso",
  Malt = "Malt",
  DG18 = "DG18",
}

export enum EvaluableType {
  Evaluable = "evaluable",
  NotEvaluable = "notEvaluable",
}

export interface Sample {
  id: string
  sampleSetId: string
  customerId: string
  description: string
  growthMedium: GrowthMedium
  evaluableType: EvaluableType
  createdAt: string
  comment: string
  batchId: string
  sampleErrors: SampleError[]
}

export interface ErrorAssignmentViewModel {
  id: string
  processId: string
  sampleSetNumber: string
  description: string
  sampleSetId: string
  growthMedium: GrowthMedium
  sampleType: SampleType
  evaluableType: EvaluableType
  sampleErrors: SampleError[]
  batchId: string
}

export enum SampleType {
  SurfaceMicrobial = "SurfaceMicrobial",
  AirMicrobial = "AirMicrobial",
}

export enum ProcedureType {
  RLT2N = "2NRLT",
  RLT1N = "1NRLT",
}

export interface SampleSet {
  id: string
  sampleSetNumber: string
  procedureType: ProcedureType
  deliveryId: string
  referenceId: string
  customerId: string
  processId: string
  externalComment: string
  comment: string
  sampleType: SampleType
  incubationStart: Date
  completed: boolean
  airSamplerHoleCount: number
  mouldDifferentiation: MouldDifferentiation[]
  samples: Sample[]
  airSamplerId?: string
}

export interface ReferenceViewModel {
  referenceId: string
  customerId: string
  processId: string
  casoCount: number
  meaCount: number
  DG18Count: number
}

export interface ApprovalViewModel {
  referenceId: string
  customerId: string
  sampleSetCount: number
  completedSampleSetCount: number
}

export interface MouldDifferentiation {
  mouldId: string
  sampleSetId: string
  value: number
  overgrown: boolean
}
