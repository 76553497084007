import { Incubation } from "@/services/backend/procedures/procedure/procedure"
import { SampleType } from "@/services/backend/samples/sample-set/sample-set"

interface MeasurementLabelComponentProps {
  incubation: Incubation
  sampleType: SampleType
}

function MeasurementLabelComponent({
  incubation,
  sampleType,
}: MeasurementLabelComponentProps) {
  return (
    <div className="flex space-x-4">
      {incubation.measurements.map(measurement => (
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row space-x-2">
            <p>Messung: {measurement.measurement}</p>
            <p>KBE / Platte</p>
          </div>
          {sampleType === SampleType.AirMicrobial && (
            <div className="flex flex-row space-x-2">
              <div>Korrigiert: {measurement.correctedMeasurement}</div>
              <p>KBE / Platte</p>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default MeasurementLabelComponent
