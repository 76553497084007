import { RouterProvider } from "react-router"
import router from "@/routes/router"
import { PersistenceContextProvider } from "./shared/persistence-state/provider"

function App() {
  return (
    <PersistenceContextProvider>
      <RouterProvider router={router} />
    </PersistenceContextProvider>
  )
}

export default App
