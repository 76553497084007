import { ErrorsRoute } from "@/routes/base-data/sample/errors/errors-route"
import { MouldsRoute } from "@/routes/base-data/sample/moulds/moulds-route"
import { BatchesRoute } from "@/routes/base-data/sample/batches/batches-route"
import { AirSamplersRoute } from "./airsamplers/air-samplers-route"

export const sampleRoutes = [
  {
    path: "sample-errors",
    element: <ErrorsRoute />,
  },
  {
    path: "moulds",
    element: <MouldsRoute />,
  },
  {
    path: "batches",
    element: <BatchesRoute />,
  },
  {
    path: "airsamplers",
    element: <AirSamplersRoute />,
  },
]
