import { Outlet } from "react-router-dom"
import {
  SideNavContainer,
  SideNavLink,
} from "@/routes/base-data/_components/layout/side-nav"

export default function BaseDataRoute() {
  return (
    <div className="mx-2 my-4 space-y-2">
      <div className="flex flex-col pt-2 sm:flex-row sm:gap-12 sm:pt-0">
        <SideNavContainer>
          <div className="mb-2 mt-4 text-sm font-medium">Stammdaten</div>
          <SideNavLink to="sample/sample-errors">Probenfehler</SideNavLink>
          <SideNavLink to="sample/moulds">Schimmelpilze</SideNavLink>
          <SideNavLink to="sample/batches">Chargen</SideNavLink>
          <SideNavLink to="sample/airsamplers">Luftkeimsammler</SideNavLink>
          {/* <div className="mb-2 mt-4 text-sm font-medium">Unternehmen</div>
          <SideNavLink to="companies">Allgemein</SideNavLink>
           companies.map(company => (
            <SideNavLink key={company.id} to={`companies/${company.id}`}>
              {company.shortName}
            </SideNavLink>
          )) */}
          <div className="mb-2 mt-4 text-sm font-medium">Entra</div>
          <SideNavLink to="entra/entra-groups">Entra Gruppen</SideNavLink>
        </SideNavContainer>
        <main className="flex-grow">
          <Outlet />
        </main>
      </div>
    </div>
  )
}
