import { PropsWithChildren, ReactNode } from "react"
import { NavLink } from "react-router-dom"

export function SideNavContainer({ children }: PropsWithChildren) {
  return <nav className="flex flex-col">{children}</nav>
}

export function SideNavLink({
  to,
  children,
}: {
  to: string
  children: ReactNode
}) {
  return (
    <NavLink
      to={to}
      end
      className={({ isActive }) =>
        isActive
          ? "border-1 hover:border-1 block border-l border-gray-700 px-2 py-1 text-sm font-medium text-gray-900 hover:border-l hover:border-gray-700 hover:text-gray-900"
          : "border-1 hover:border-1 block border-l border-gray-300 px-2 py-1 text-sm font-medium text-gray-500 hover:border-l hover:border-gray-700 hover:text-gray-900"
      }
    >
      {children}
    </NavLink>
  )
}
