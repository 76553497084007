import { ReactElement } from "react"
import { useGetSampleSetQuery } from "@/services/backend/samples/sample-set/service"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"

interface MouldResultProps {
  sampleSetId: string
}

function MouldResult({ sampleSetId }: MouldResultProps): ReactElement {
  const { data, error } = useGetSampleSetQuery({ id: sampleSetId })

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (data != null && data.mouldDifferentiation.length > 0) {
    const amount = data.mouldDifferentiation.filter(
      diff => diff.value > 0,
    ).length

    if (amount > 0) {
      return <div>{amount} Pilze identifiziert </div>
    }
  }

  return <div>Keine Pilze identifiziert</div>
}

export default MouldResult
