import type {
  ColumnDef,
  Table as ReactTable,
  VisibilityState,
} from "@tanstack/react-table"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/data-table"
import {
  SampleSet,
  SampleType,
} from "@/services/backend/samples/sample-set/sample-set"
import { useSampleSetListByProcessQuery } from "@/services/backend/samples/sample-set/service"
import ProcedureSelector from "@/routes/processes/[processId]/process/set-overview/_components/procedure-selector"
import SetContentLabel from "@/routes/processes/[processId]/process/set-overview/_components/set-content-label"
import { CommentInputComponent } from "@/routes/processes/[processId]/process/set-overview/_components/comment-component"
import { H4 } from "@/shared/components/typography"
import AirSamplerSelector from "./air-sampler-selector"

const createTableColumns = (): ColumnDef<SampleSet>[] => [
  {
    id: "sampleSetNumber",
    accessorKey: "sampleSetNumber",
    header: "Probensetnummer",
    cell: ({ row }) => {
      const sampleSetData = row.original
      return sampleSetData.sampleSetNumber
    },
  },
  {
    id: "procedureType",
    accessorKey: "procedureType",
    header: "Verfahren",
    cell: ({ row }) => {
      const sampleSetData = row.original
      return <ProcedureSelector sampleSet={sampleSetData} />
    },
  },
  {
    id: "airSampler",
    accessorKey: "airSamplerId",
    header: "Luftkeimsammler",
    cell: ({ row }) => {
      const sampleSetData = row.original
      return <AirSamplerSelector sampleSet={sampleSetData} />
    },
  },
  {
    id: "comment",
    accessorKey: "comment",
    header: "Kommentar",
    cell: ({ row }) => {
      const sampleSetData = row.original
      return <CommentInputComponent sampleSet={sampleSetData} />
    },
  },
  {
    id: "externalComment",
    accessorKey: "externalComment",
    header: "Externer Kommentar",
    cell: ({ row }) => {
      const sampleSetData = row.original
      return sampleSetData.externalComment == null ? (
        <p>Kein Kommentar hinterlegt</p>
      ) : (
        <p>{sampleSetData.externalComment}</p>
      )
    },
  },
  {
    id: "content",
    accessorKey: "id",
    header: "Inhalt",
    cell: ({ row }) => {
      const sampleSetData = row.original
      return <SetContentLabel samples={sampleSetData.samples} />
    },
  },
]

interface SampleSetTableProps {
  processId: string
  sampleType: SampleType
  label: string
  visibilityState?: VisibilityState
}

export function SampleSetTable({
  processId,
  sampleType,
  label,
  visibilityState = {},
}: SampleSetTableProps) {
  const {
    data: list,
    isLoading,
    isFetching,
    error,
  } = useSampleSetListByProcessQuery(
    { processId, sampleType },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const cols = createTableColumns()
  const table = useDataTableController(cols, list?.data || [], visibilityState)

  if (isLoading || isFetching) {
    return <Skeleton table={table} columns={cols} />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <div className="flex w-full flex-col space-y-4">
      <H4>{label}</H4>
      <DataTableContainer className="w-full">
        <DataTable table={table} columns={cols} />
      </DataTableContainer>
    </div>
  )
}

function Skeleton<TData>(props: {
  table: ReactTable<TData>
  columns: ColumnDef<TData>[]
}) {
  const { table, columns } = props
  return (
    <DataTableContainer className="animate-pulse">
      <DataTable table={table} columns={columns} />
    </DataTableContainer>
  )
}
