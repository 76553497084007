import { backendApi } from "@/services/api"
import type {
  Process,
  ProcessStatus,
} from "@/services/backend/processes/process/process"

const processesApi = backendApi.injectEndpoints({
  endpoints: build => ({
    putProcess: build.mutation<Process, PutRequest>({
      query: request => ({
        url: `/processes`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Process"],
    }),
    startProcess: build.mutation<
      StartProcessForDeliveryResponse,
      StartProcessForDeliveryRequest
    >({
      query: request => ({
        url: `/processes/start`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Process"],
    }),
    completeProcess: build.mutation<CompleteRequestResponse, CompleteRequest>({
      query: request => ({
        url: `/processes/complete`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Process", "SampleSet", "Procedures"],
    }),
    updateStatus: build.mutation<UpdateStatusResponse, UpdateStatusRequest>({
      query: request => ({
        url: `/processes/status`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Process", "SampleSet", "Procedures"],
    }),
    processesList: build.query<List, ListRequest>({
      query: request => ({
        url: `/processes/list`,
        method: "GET",
        params: {
          limit: request.limit,
          offset: request.offset,
          customerId: request.customerId,
        },
      }),
      providesTags: ["Process"],
    }),
    getProcess: build.query<Process, GetRequest>({
      query: request => ({
        url: `/processes`,
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _e, request) => [
        { type: "Process", id: request.id },
      ],
    }),
  }),
})

export const {
  useProcessesListQuery,
  useGetProcessQuery,
  useUpdateStatusMutation,
  usePutProcessMutation,
  useCompleteProcessMutation,
  useStartProcessMutation,
} = processesApi

export interface PutRequest {
  id: string
  customerId: string
  topic: string
  processNumber: string
}

export interface GetRequest {
  id: string
}

export interface DeleteRequest {
  id: string
}

export interface ListRequest {
  limit: number
  offset: number
  customerId?: string
}

export interface List {
  count: number
  data: Process[]
}

export interface CompleteRequest {
  processId: string
}

export interface CompleteRequestResponse {}

export interface UpdateStatusRequest {
  id: string
  status: ProcessStatus
}

export interface UpdateStatusResponse {}

export interface StartProcessForDeliveryRequest {
  deliveryId: string
}

export interface StartProcessForDeliveryResponse {}
