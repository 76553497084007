import type { ColumnDef, Table as ReactTable } from "@tanstack/react-table"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/data-table"
import { useListApiKeysQuery } from "@/services/backend/api-key/service"
import { useParams } from "react-router-dom"
import { ApiKey } from "@/services/backend/api-key/api-key"
import { CustomerName } from "@/shared/components/customer/customer-name"
import { DeleteApiKeyForm } from "@/routes/customers/[customerId]/api-key/_components/delete-api-key"

const tableColumns: ColumnDef<ApiKey>[] = [
  {
    id: "Kunde",
    accessorKey: "customerId",
    header: "Kunde",
    cell: ({ row }) => <CustomerName customerId={row.original.customerId} />,
  },
  {
    id: "Ablaufzeitpunkt",
    accessorKey: "exp",
    header: "Ablaufzeitpunkt",
    cell: ({ row }) => {
      const date = new Date(row.original.exp * 1000)
      return date.toLocaleString("de-DE", { timeZone: "Europe/Berlin" })
    },
  },
  {
    id: "Aktiv",
    accessorKey: "active",
    header: "Aktiv",
    cell: ({ row }) => (row.original.active ? "Ja" : "Nein"),
  },
  {
    id: "Optionen",
    accessorKey: "id",
    header: "Optionen",
    cell: ({ row }) => (
      <div className="flex flex-row space-x-2">
        <DeleteApiKeyForm id={row.original.id} />
      </div>
    ),
  },
]

export function ApiKeyTable() {
  const { customerId } = useParams()
  const {
    data: list,
    isLoading,
    isFetching,
    error,
  } = useListApiKeysQuery(
    { customerId: customerId as string },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const table = useDataTableController(tableColumns, list?.data || [])

  if (isLoading || isFetching) {
    return <Skeleton table={table} columns={tableColumns} />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <DataTableContainer>
      <DataTable table={table} columns={tableColumns} />
    </DataTableContainer>
  )
}

function Skeleton<TData>(props: {
  table: ReactTable<TData>
  columns: ColumnDef<TData>[]
}) {
  const { table, columns } = props
  return (
    <DataTableContainer className="animate-pulse">
      <DataTable table={table} columns={columns} />
    </DataTableContainer>
  )
}
