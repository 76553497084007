import { EntraGroupsTable } from "@/routes/base-data/entra/entra-groups/components/entra-groups-table"
import { PutEntraGroupDialog } from "@/routes/base-data/entra/entra-groups/components/put-entra-group-dialog"
import { H2 } from "@/shared/components/typography"

export function EntraGroupsRoute() {
  return (
    <div>
      <H2 className="flex w-full justify-between">
        <span>Entra Gruppen</span>
        <PutEntraGroupDialog />
      </H2>
      <div className="pt-4 text-muted-foreground">
        Entra Gruppen werden genutzt um die Zugehörigkeit eines Nutzers zu einem
        Unternehmen herzustellen. Eine Gruppe kann mehreren Unternehmen
        zugeordnet sein. Es wird allerdings empfohlen, Entra so zu
        konfigurieren, dass Nutzer einer eindeutigen Gruppe welche einem
        Unternehmen entspricht zugeordnet sind.
      </div>
      <div className="pt-4">
        <EntraGroupsTable />
      </div>
    </div>
  )
}
