import { ReactElement, useState } from "react"
import { Input } from "@/shared/shadcn/components/ui/input"
import { Checkbox } from "@/shared/shadcn/components/ui/checkbox"
import { Mould } from "@/services/backend/mould/mould"
import {
  MouldDifferentiation,
  SampleSet,
} from "@/services/backend/samples/sample-set/sample-set"
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce"
import {
  PutMouldDifferentiation,
  usePutMouldDifferentiationMutation,
} from "@/services/backend/samples/sample-set/service"

interface ScrollableDifferentiationListProps {
  sampleSet: SampleSet
  moulds: Mould[]
}

export function ScrollableDifferentiationList({
  sampleSet,
  moulds,
}: ScrollableDifferentiationListProps): ReactElement {
  return (
    <div className="h-[400px] w-full overflow-y-auto rounded border border-gray-300 p-1">
      <ul className="space-y-2">
        {moulds.map(item => (
          <li>
            <MouldDifferentiationListElement
              isCompleted={sampleSet.completed}
              sampleSetId={sampleSet.id}
              mould={item}
              differentiation={sampleSet.mouldDifferentiation.find(
                value => value.mouldId === item.id,
              )}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

interface MouldDifferentiationListElementProps {
  differentiation?: MouldDifferentiation
  mould: Mould
  sampleSetId: string
  isCompleted: boolean
}

export function MouldDifferentiationListElement({
  differentiation,
  mould,
  sampleSetId,
  isCompleted,
}: MouldDifferentiationListElementProps): ReactElement {
  const [request, setRequest] = useState<PutMouldDifferentiation>({
    mouldId: mould.id,
    sampleSetId,
    value: 0,
    overgrown: false,
  })
  const [putDifferentiation, { isLoading, error, isSuccess, reset }] =
    usePutMouldDifferentiationMutation()
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    putDifferentiation,
    isLoading,
    error,
    isSuccess,
    reset,
    300,
    { toastError: true },
  )

  return (
    <div className="flex w-full flex-row items-center justify-between p-2">
      <p>{mould.description}</p>
      <div className="w-34 flex flex-row items-center space-x-2">
        <Input
          disabled={isCompleted}
          className="w-16"
          type="number"
          step={1}
          min={0}
          defaultValue={differentiation?.value}
          onChange={e =>
            setRequest({ ...request, value: Number(e.currentTarget.value) })
          }
        />
        <Checkbox
          disabled={isCompleted}
          defaultChecked={differentiation?.overgrown}
          onCheckedChange={checked =>
            setRequest({ ...request, overgrown: checked as boolean })
          }
        />
        <p>Überw.</p>
      </div>
    </div>
  )
}
