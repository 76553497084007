import ConfirmationDialog from "@/shared/components/confirmation/confirmation-dialog"
import { Button } from "@/shared/shadcn/components/ui/button"
import { useParams } from "react-router-dom"
import { ProcedureType } from "@/services/backend/samples/sample-set/sample-set"
import ProcedureTable from "@/routes/processes/[processId]/process/analytics/_components/procedure-table"
import {
  useGetProcessQuery,
  useUpdateStatusMutation,
} from "@/services/backend/processes/process/service"
import { skipToken } from "@reduxjs/toolkit/query"
import { format } from "date-fns"
import { PersistenceStateUsingContext } from "@/shared/persistence-state/persistence-state-info"

const getTimestamp = (timestamp: string): string => {
  const formattedDate = format(timestamp, "dd.MM.yyyy")
  const formattedTime = format(timestamp, "HH:mm:ss")
  return `${formattedDate} - ${formattedTime}`
}

export function AnalyticsIndexRoute() {
  const { processId } = useParams()
  const { data: process } = useGetProcessQuery(
    processId != null ? { id: processId } : skipToken,
  )
  const [startProcess] = useUpdateStatusMutation()

  return (
    <div className="flex flex-col space-y-4">
      {process != null && (
        <div className="flex flex-row justify-between">
          {process.status === "Created" || process.status === "Scheduled" ? (
            <ConfirmationDialog
              trigger={
                <Button variant="outline">
                  <p>Inkubation starten</p>
                </Button>
              }
              title="Inkubation starten"
              description="Hiermit wird der Zählprozess in Gang gesetzt."
              onConfirm={() =>
                startProcess({
                  id: processId as string,
                  status: "IncubationStarted",
                })
              }
            />
          ) : (
            <div className="flex flex-row space-x-2">
              <h4 className="font-bold">Inkubationsstart:</h4>
              <p>{getTimestamp(process.incubationStart)}</p>
            </div>
          )}
          <PersistenceStateUsingContext />
        </div>
      )}

      <ProcedureTable
        label="1N-RLT"
        processId={processId as string}
        procedureType={ProcedureType.RLT1N}
        visibilityState={{
          "measurement-2": false,
        }}
      />

      <ProcedureTable
        label="2N-RLT"
        processId={processId as string}
        procedureType={ProcedureType.RLT2N}
      />
    </div>
  )
}
