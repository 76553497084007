import type { ColumnDef, Table as ReactTable } from "@tanstack/react-table"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/data-table"
import { useListMouldsQuery } from "@/services/backend/mould/service"
import type { Mould } from "@/services/backend/mould/mould"

const tableColumns: ColumnDef<Mould>[] = [
  {
    id: "Beschreibung",
    accessorKey: "description",
    header: "Beschreibung",
    cell: ({ row }) => {
      const mouldData = row.original
      return <div>{mouldData.description}</div>
    },
  },
]

export function MouldListTable() {
  const {
    data: list,
    isLoading,
    isFetching,
    error,
  } = useListMouldsQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const table = useDataTableController(tableColumns, list?.data || [])

  if (isLoading || isFetching) {
    return <Skeleton table={table} columns={tableColumns} />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <DataTableContainer>
      <DataTable table={table} columns={tableColumns} />
    </DataTableContainer>
  )
}

function Skeleton<TData>(props: {
  table: ReactTable<TData>
  columns: ColumnDef<TData>[]
}) {
  const { table, columns } = props
  return (
    <DataTableContainer className="animate-pulse">
      <DataTable table={table} columns={columns} />
    </DataTableContainer>
  )
}
