import { backendApi } from "@/services/api"
import { ApiKey } from "@/services/backend/api-key/api-key"

const apiKeyApi = backendApi.injectEndpoints({
  endpoints: build => ({
    createApiKey: build.mutation<PutResponse, PutRequest>({
      query: request => ({
        url: `/customers/api-key`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["ApiKey"],
    }),
    deleteApiKey: build.mutation<DeleteResponse, DeleteRequest>({
      query: request => ({
        url: `/customers/api-key`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: ["ApiKey"],
    }),
    listApiKeys: build.query<List, ListRequest>({
      query: request => ({
        url: `/customers/api-key/list`,
        method: "GET",
        params: {
          customerId: request.customerId,
        },
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "ApiKey" as const,
                id,
              })),
              { type: "ApiKey", id: "LIST" },
            ]
          : [{ type: "ApiKey", id: "LIST" }],
    }),
  }),
})

export const {
  useCreateApiKeyMutation,
  useListApiKeysQuery,
  useDeleteApiKeyMutation,
} = apiKeyApi

interface PutRequest {
  id: string
  customerId: string
  exp: number
  active: boolean
}

interface PutResponse {
  result: string
}

interface DeleteRequest {
  id: string
}

interface DeleteResponse {}

interface ListRequest {
  customerId: string
}

interface List {
  data: ApiKey[]
}
