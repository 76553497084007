import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { v4 as uuidv4 } from "uuid"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/shared/shadcn/components/ui/form"
import { Input } from "@/shared/shadcn/components/ui/input"
import { Button } from "@/shared/shadcn/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/shadcn/components/ui/dialog"
import { useCreateApiKeyMutation } from "@/services/backend/api-key/service"
import SecretField from "@/shared/components/secrets/secret-field"
import { useState } from "react"

const FormSchema = z.object({
  exp: z.string(),
})

interface CreateApiKeyFormProps {
  customerId: string
}

export function CreateApiKeyForm({ customerId }: CreateApiKeyFormProps) {
  const [isOpen, setIsOpen] = useState(false) // Modal-Open-Zustand
  const [createApiKey, result] = useCreateApiKeyMutation()
  const { isSuccess, reset, data: responseData } = result

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  })

  const handleClose = () => {
    if (isOpen) {
      reset()
      form.reset()
      form.resetField("exp")
    }
    setIsOpen(!isOpen)
  }

  function onSubmit(data: z.infer<typeof FormSchema>) {
    const validTime = Number(data.exp) * 365 * 24 * 60 * 60
    const currentTimestamp = Math.floor(Date.now() / 1000)
    const timestamp = currentTimestamp + validTime
    createApiKey({
      id: uuidv4(),
      customerId,
      exp: timestamp,
      active: true,
    })
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogTrigger asChild>
        <Button variant="outline">Neuen API-Key erstellen</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Key anlegen</DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="w-full space-y-6"
            >
              <FormField
                control={form.control}
                name="exp"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Laufzeit</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        min={1}
                        placeholder="Laufzeit in Jahren"
                        {...field}
                      />
                    </FormControl>
                    <FormDescription>Laufzeit in Jahren</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {!isSuccess && (
                <Button disabled={isSuccess} type="submit">
                  Submit
                </Button>
              )}
            </form>
          </Form>
          {isSuccess && <SecretField secret={responseData.result} />}
        </div>
      </DialogContent>
    </Dialog>
  )
}
