import { useShowCompanyQuery } from "@/services/backend/company/company"

export function CompanyName({
  companyId,
  short = false,
}: {
  companyId: string
  short?: boolean
}) {
  const {
    data: company,
    isLoading,
    isError,
  } = useShowCompanyQuery({ id: companyId })
  if (isLoading) {
    return (
      <span className="animate-pulse">
        <span className="block h-2 rounded-full bg-gray-200" />
      </span>
    )
  }
  if (isError) {
    return <span>Fehler beim Laden des Firmennamen</span>
  }

  if (short ?? false) {
    return <span>{company?.shortName}</span>
  }
  return <span>{company?.name}</span>
}
