import { EntraRoute } from "@/routes/base-data/entra/entra-route"
import { entraRoutes } from "@/routes/base-data/entra/entra-routes"
import type { RouteObject } from "react-router-dom"
import { SampleRoute } from "@/routes/base-data/sample/sample-route"
import { sampleRoutes } from "@/routes/base-data/sample/sample-routes"

export const baseDataRoutes: RouteObject[] = [
  /* {
    path: "companies",
    element: <CompaniesRoute />,
    children: [
      {
        index: true,
        element: <CompanyListRoute />,
      },
    ],
  },
  {
    path: "companies/:id",
    element: <CompanyRoute />,
  }, */
  {
    path: "entra",
    element: <EntraRoute />,
    children: entraRoutes,
  },
  {
    path: "sample",
    element: <SampleRoute />,
    children: sampleRoutes,
  },
]
