import { H2 } from "@/shared/components/typography"
import { MouldListTable } from "@/routes/base-data/sample/moulds/_components/mould-list-table"
import { PutMouldDialogForm } from "@/routes/base-data/sample/moulds/_components/put-mould-dialog"

export function MouldsRoute() {
  return (
    <div>
      <H2 className="flex w-full justify-between">
        <span>Schimmelpilze</span>
        <PutMouldDialogForm />
      </H2>
      <div className="pt-4 text-muted-foreground">
        Schimmelpilze für die Differenzierung können hier definiert werden.
      </div>
      <div className="pt-4">
        <MouldListTable />
      </div>
    </div>
  )
}
