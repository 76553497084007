import { ReactElement } from "react"
import {
  GrowthMedium,
  Sample,
} from "@/services/backend/samples/sample-set/sample-set"

interface SetContentLabelProps {
  samples: Sample[]
}

function SetContentLabel({ samples }: SetContentLabelProps): ReactElement {
  const getCasoLabel = (): ReactElement => {
    if (samples != null) {
      const count = samples.filter(
        item => item.growthMedium === GrowthMedium.Caso,
      ).length

      return (
        <div className="flex space-x-2">
          <span className="font-bold">Caso:</span>
          <span>{count}</span>
        </div>
      )
    }

    return <div />
  }

  const getMaltLabel = (): ReactElement => {
    if (samples != null) {
      const count = samples.filter(
        item => item.growthMedium === GrowthMedium.Malt,
      ).length

      return (
        <div className="flex space-x-2">
          <span className="font-bold">Malz:</span>
          <span>{count}</span>
        </div>
      )
    }

    return <div />
  }

  const getDG18Label = (): ReactElement => {
    if (samples != null) {
      const count = samples.filter(
        item => item.growthMedium === GrowthMedium.DG18,
      ).length

      return (
        <div className="flex space-x-2">
          <span className="font-bold">DG18:</span>
          <span>{count}</span>
        </div>
      )
    }

    return <div />
  }

  return (
    <div className="flex flex-col">
      {getCasoLabel()}
      {getMaltLabel()}
      {getDG18Label()}
    </div>
  )
}

export default SetContentLabel
