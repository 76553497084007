import type { ColumnDef, Table as ReactTable } from "@tanstack/react-table"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/data-table"
import type { ReferenceViewModel } from "@/services/backend/samples/sample-set/sample-set"
import { useSampleSetListByReferenceQuery } from "@/services/backend/samples/sample-set/service"
import { NIL } from "uuid"
import { NavLink } from "react-router-dom"
import { Button } from "@/shared/shadcn/components/ui/button"

const createTableColumns = (): ColumnDef<ReferenceViewModel>[] => [
  {
    id: "Caso",
    accessorKey: "casoCount",
    header: "Caso",
    cell: ({ row }) => {
      const sampleSetData = row.original
      return sampleSetData.casoCount
    },
  },
  {
    id: "MEA",
    accessorKey: "meaCount",
    header: "MEA",
    cell: ({ row }) => {
      const sampleSetData = row.original
      return sampleSetData.meaCount
    },
  },
  {
    id: "DG18",
    accessorKey: "DG18Count",
    header: "DG18",
    cell: ({ row }) => {
      const sampleSetData = row.original
      return sampleSetData.DG18Count
    },
  },
  {
    id: "Referenz",
    accessorKey: "referenceId",
    header: "Referenz",
    cell: ({ row }) => {
      const sampleSetData = row.original
      return <div>{sampleSetData.referenceId}</div>
    },
  },
  {
    id: "processId",
    accessorKey: "processId",
    header: "Prozess",
    cell: ({ row }) => {
      const sampleSetData = row.original

      if (sampleSetData.processId == null || sampleSetData.processId === NIL) {
        return "Kein Prozess gestartet"
      }

      return (
        <NavLink to={`/processes/${sampleSetData.processId}`}>
          <Button variant="outline">Zum Prozess</Button>
        </NavLink>
      )
    },
  },
]

interface SampleSetTableProps {
  deliveryId: string
}

export function SampleSetTable({ deliveryId }: SampleSetTableProps) {
  const {
    data: list,
    isLoading,
    isFetching,
    error,
  } = useSampleSetListByReferenceQuery(
    { deliveryId },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const cols = createTableColumns()
  const table = useDataTableController(cols, list?.data || [])

  if (isLoading || isFetching) {
    return <Skeleton table={table} columns={cols} />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <div className="flex w-full flex-col space-y-4">
      <DataTableContainer className="w-full">
        <DataTable table={table} columns={cols} />
      </DataTableContainer>
    </div>
  )
}

function Skeleton<TData>(props: {
  table: ReactTable<TData>
  columns: ColumnDef<TData>[]
}) {
  const { table, columns } = props
  return (
    <DataTableContainer className="animate-pulse">
      <DataTable table={table} columns={columns} />
    </DataTableContainer>
  )
}
