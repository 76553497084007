import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { reAuthOn401Wrapper } from "@/services/re-auth-on-401"
import { provideBackendToken } from "@/shared/lib/authorization/provide-backend-token"

export const backendApi = createApi({
  baseQuery: reAuthOn401Wrapper(
    fetchBaseQuery({
      baseUrl: import.meta.env.VITE_BACKEND_URL,
      prepareHeaders: async headers => {
        const accessToken: string = await provideBackendToken()
        headers.set("Authorization", `Bearer ${accessToken}`)
        return headers
      },
    }),
  ),
  endpoints: () => ({}),
  reducerPath: "backendApi",
  tagTypes: [
    "Delivery",
    "SampleSet",
    "Process",
    "Customer",
    "Contact",
    "Sample",
    "AuthEntraGroup",
    "Company",
    "SampleError",
    "Mould",
    "MouldDifferentiation",
    "ApiKey",
    "Procedures",
    "Incubations",
    "Calculation",
    "Batches",
    "AirSamplers",
  ],
})
