import type { ColumnDef, Table as ReactTable } from "@tanstack/react-table"
import { useState } from "react"
import { NavLink } from "react-router-dom"
import { Search } from "lucide-react"
import { Button } from "@/shared/shadcn/components/ui/button"
import type { ListRequest } from "@/services/backend/deliveries/delivery/service"
import { useDeliveriesListQuery } from "@/services/backend/deliveries/delivery/service"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import {
  DataTable,
  DataTableBrowserPagination,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/data-table"
import { StateIcon } from "@/routes/deliveries/_components/state-label"
import type { Delivery } from "@/services/backend/deliveries/delivery/delivery"
import { formatDate, formatTime } from "@/shared/utilities/date"
import { CustomerName } from "@/shared/components/customer/customer-name"

const tableColumns: ColumnDef<Delivery>[] = [
  {
    id: "id",
    accessorKey: "id",
    header: "Liefernummer",
    cell: ({ row }) => {
      const deliveryData = row.original
      return <div>{deliveryData.id}</div>
    },
  },
  {
    id: "description",
    accessorKey: "description",
    header: "Beschreibung",
    cell: ({ row }) => {
      const deliveryData = row.original
      return <div>{deliveryData.description}</div>
    },
  },
  {
    id: "customerId",
    accessorKey: "customerId",
    header: "Kunde",
    cell: ({ row }) => {
      const deliveryData = row.original
      return <CustomerName customerId={deliveryData.customerId} />
    },
  },
  {
    id: "deliveryState",
    accessorKey: "deliveryState",
    header: "Lieferstatus",
    cell: ({ row }) => {
      const deliveryData = row.original
      return <StateIcon label={deliveryData.deliveryState} />
    },
  },
  {
    id: "creationDate",
    accessorKey: "creationDate",
    header: "Erstellungsdatum",
    cell: ({ row }) => {
      const deliveryData = row.original
      return (
        <div className="flex flex-col">
          <span className="font-bold">
            {formatDate(deliveryData.creationDate)}
          </span>
          <span>{formatTime(deliveryData.creationDate)}</span>
        </div>
      )
    },
  },
  {
    id: "Aktionen",
    header: "Aktionen",
    cell: ({ row }) => {
      const deliveryData = row.original
      return (
        <NavLink to={`${deliveryData.id}`}>
          <Button variant="ghost" size="sm" className="-mb-2.5 -mt-1.5">
            <Search className="h-4 w-4" />
          </Button>
        </NavLink>
      )
    },
  },
]

export function DeliveriesListTable() {
  const [request] = useState<ListRequest>({
    state: {
      active: false,
      values: [],
    },
    orderBy: ["topic"],
    limit: 50,
    offset: 0,
  })
  const {
    data: list,
    isLoading,
    isFetching,
    error,
  } = useDeliveriesListQuery(request, {
    refetchOnMountOrArgChange: true,
  })

  const table = useDataTableController(tableColumns, list?.data || [])

  if (isLoading || isFetching) {
    return <Skeleton table={table} columns={tableColumns} />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <DataTableContainer>
      <DataTable table={table} columns={tableColumns} />
      <DataTableBrowserPagination table={table} />
    </DataTableContainer>
  )
}

function Skeleton<TData>(props: {
  table: ReactTable<TData>
  columns: ColumnDef<TData>[]
}) {
  const { table, columns } = props
  return (
    <DataTableContainer className="animate-pulse">
      <DataTable table={table} columns={columns} />
      <DataTableBrowserPagination table={table} />
    </DataTableContainer>
  )
}
