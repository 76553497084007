import { H2 } from "@/shared/components/typography"
import { PutErrorDialogForm } from "@/routes/base-data/sample/errors/_components/put-error-dialog"
import { SampleErrorListTable } from "@/routes/base-data/sample/errors/_components/sample-error-list-table"

export function ErrorsRoute() {
  return (
    <div>
      <H2 className="flex w-full justify-between">
        <span>Probenfehler</span>
        <PutErrorDialogForm />
      </H2>
      <div className="pt-4 text-muted-foreground">
        Probenfehler sind Fehler, die während der Probendokumentation angegeben
        werden können. Die Liste der hier aufgeführten Probenfehler sind für die
        Anwender verfügbar.
      </div>
      <div className="pt-4">
        <SampleErrorListTable />
      </div>
    </div>
  )
}
