import { backendApi } from "@/services/api"
import {
  ApprovalViewModel,
  MouldDifferentiation,
  ProcedureType,
  ReferenceViewModel,
  SampleSet,
  SampleType,
} from "@/services/backend/samples/sample-set/sample-set"

const sampleSetApi = backendApi.injectEndpoints({
  endpoints: build => ({
    putSampleSet: build.mutation<SampleSet, PutRequest>({
      query: request => ({
        url: `/samples/sampleset`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["SampleSet"],
    }),
    putMouldDifferentiation: build.mutation<
      MouldDifferentiation,
      PutMouldDifferentiation
    >({
      query: request => ({
        url: `/samples/sampleset/differentiation`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["SampleSet"],
    }),
    sampleSetList: build.query<List, ListRequest>({
      query: request => ({
        url: `/samples/sampleset/list`,
        method: "GET",
        params: {
          customerId: request.customerId,
          deliveryId: request.deliveryId,
          limit: request.limit,
          offset: request.offset,
        },
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "SampleSet" as const,
                id,
              })),
              { type: "SampleSet", id: "LIST" },
            ]
          : [{ type: "SampleSet", id: "LIST" }],
    }),
    sampleSetListByProcess: build.query<List, ListByProcessRequest>({
      query: request => ({
        url: `/samples/sampleset/listbyprocess`,
        method: "GET",
        params: {
          processId: request.processId,
          sampleType: request.sampleType,
        },
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "SampleSet" as const,
                id,
              })),
              { type: "SampleSet", id: "LIST" },
            ]
          : [{ type: "SampleSet", id: "LIST" }],
    }),
    sampleSetListByDelivery: build.query<List, ListByDeliveryRequest>({
      query: request => ({
        url: `/samples/sampleset/listbydelivery`,
        method: "GET",
        params: {
          deliveryId: request.deliveryId,
        },
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "SampleSet" as const,
                id,
              })),
              { type: "SampleSet", id: "LIST" },
            ]
          : [{ type: "SampleSet", id: "LIST" }],
    }),
    getSampleSet: build.query<SampleSet, GetRequest>({
      query: request => ({
        url: `/samples/sampleset`,
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _e, request) => [
        { type: "SampleSet", id: request.id },
      ],
    }),
    assignToProcess: build.mutation<SampleSet, AssignToProcessRequest>({
      query: request => ({
        url: `/samples/sampleset/assign-to-process`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["SampleSet", "Delivery"],
    }),
    sampleSetListByReference: build.query<
      ListByReference,
      ListByReferenceRequest
    >({
      query: request => ({
        url: `/samples/sampleset/listbyreference`,
        method: "GET",
        params: {
          deliveryId: request.deliveryId,
        },
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ referenceId }) => ({
                type: "SampleSet" as const,
                referenceId,
              })),
              { type: "SampleSet", id: "LIST" },
            ]
          : [{ type: "SampleSet", id: "LIST" }],
    }),
    sampleSetListForApproval: build.query<
      ListForApproval,
      ListForApprovalRequest
    >({
      query: () => ({
        url: `/samples/sampleset/listforapproval`,
        method: "GET",
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ referenceId }) => ({
                type: "SampleSet" as const,
                referenceId,
              })),
              { type: "SampleSet", id: "LIST" },
            ]
          : [{ type: "SampleSet", id: "LIST" }],
    }),
  }),
})

export const {
  useGetSampleSetQuery,
  useSampleSetListQuery,
  useSampleSetListByReferenceQuery,
  useAssignToProcessMutation,
  useSampleSetListByProcessQuery,
  useSampleSetListByDeliveryQuery,
  usePutSampleSetMutation,
  useSampleSetListForApprovalQuery,
  usePutMouldDifferentiationMutation,
} = sampleSetApi

export interface PutRequest {
  id: string
  customerId: string
  deliveryId: string
  procedureType: ProcedureType
  referenceId?: string
  sampleSetNumber?: string
  comment?: string
  externalComment?: string
  sampleType?: SampleType
  airSamplerId?: string
}

export interface PutCommentRequest {
  id: string
  comment: string
}

export interface PutCommentResponse {}

export interface PutMouldDifferentiation {
  mouldId: string
  sampleSetId: string
  value: number
  overgrown: boolean
}

export interface GetRequest {
  id: string
}

export interface DeleteRequest {
  id: string
}

export interface ListByProcessRequest {
  processId: string
  sampleType: SampleType
}

export interface ListByDeliveryRequest {
  deliveryId: string
}

export interface ListByReferenceRequest {
  deliveryId: string
}

export interface ListByReference {
  count: number
  data: ReferenceViewModel[]
}

export interface ListForApprovalRequest {}

export interface ListForApproval {
  count: number
  data: ApprovalViewModel[]
}

export interface ListRequest {
  customerId: string
  deliveryId: string
  limit: number
  offset: number
}

export interface List {
  count: number
  data: SampleSet[]
}

export interface AssignToProcessRequest {
  processId: string
  referenceId: string
}
