import { Procedure } from "@/services/backend/procedures/procedure/procedure"
import { growthMedia } from "@/shared/components/enum-labels"
import { Card } from "@/shared/shadcn/components/ui/card"
import { ReactElement } from "react"

interface IncubationMetadataRowProps {
  procedure: Procedure
}

function IncubationMetadataRow({
  procedure,
}: IncubationMetadataRowProps): ReactElement {
  if (
    !procedure ||
    !procedure.incubation ||
    procedure.incubation.length === 0
  ) {
    return <div>Keine Inkubationen gefunden</div>
  }

  return (
    <div className="flex flex-col space-y-4">
      {procedure?.incubation.map(inc => (
        <Card className="flex flex-col space-y-2 p-4">
          <div className="flex flex-row space-x-1">
            <p className="font-bold">Art: </p>
            <p>{growthMedia[inc.growthMedium]}</p>
          </div>
          <div className="flex flex-col">
            <p className="font-bold">Inkubationszeit: </p>
            <p>Min: {inc.minIncubationTime} Stunden</p>
            <p>Max: {inc.maxIncubationTime} Stunden</p>
          </div>
          <div className="flex flex-col">
            <p className="font-bold">Inkubationstemperatur: </p>
            <p>Min: {inc.minTemperature} Grad Celsisus</p>
            <p>Max: {inc.maxTemperature} Grad Celsisus</p>
          </div>
        </Card>
      ))}
    </div>
  )
}

export default IncubationMetadataRow
