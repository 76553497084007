import { RouteTitle } from "@/shared/components/titles"
import { ProcessesListTable } from "@/routes/processes/_components/processes-list-table"

function ProcessesRoute() {
  return (
    <div className="mx-2 my-4 space-y-2">
      <RouteTitle>Vorgänge</RouteTitle>
      <ProcessesListTable />
    </div>
  )
}

export default ProcessesRoute
