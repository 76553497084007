import { useParams } from "react-router"
import { ProcedureType } from "@/services/backend/samples/sample-set/sample-set"
import ApprovalTable from "@/routes/processes/[processId]/process/approval/_components/approval-table"
import ConfirmationDialog from "@/shared/components/confirmation/confirmation-dialog"
import { Button } from "@/shared/shadcn/components/ui/button"
import {
  useCompleteProcessMutation,
  useGetProcessQuery,
} from "@/services/backend/processes/process/service"
import { skipToken } from "@reduxjs/toolkit/query"

export function ApprovalIndexRoute() {
  const { processId } = useParams()
  const { data: process } = useGetProcessQuery(
    processId ? { id: processId } : skipToken,
  )
  const [completeProcess] = useCompleteProcessMutation()

  return (
    <div className="flex flex-col space-y-4">
      {processId != null && process != null && !process.completed && (
        <div className="flex flex-row space-x-2">
          <ConfirmationDialog
            trigger={
              <Button variant="outline">
                <p>Prozess freigeben</p>
              </Button>
            }
            title="Prozess freigeben"
            description="Hiermit wird der Prozess freigeben und ist damit unveränderlich. Möchten Sie fortfahren?"
            onConfirm={() => completeProcess({ processId })}
          />
        </div>
      )}
      <ApprovalTable
        label="1N-RLT"
        procedureType={ProcedureType.RLT1N}
        processId={processId as string}
        visibilityState={{
          "measurement-2": false,
          actions: false,
        }}
      />
      <ApprovalTable
        label="2N-RLT"
        procedureType={ProcedureType.RLT2N}
        processId={processId as string}
      />
    </div>
  )
}
