import { useGetSampleSetQuery } from "@/services/backend/samples/sample-set/service"
import { RTKQueryErrorAlert } from "@/shared/components/errors/rtk-query-error-alert"

interface SampleSetNumberProps {
  sampleSetId: string
}

export function SampleSetNumber({ sampleSetId }: SampleSetNumberProps) {
  const { data, error } = useGetSampleSetQuery({ id: sampleSetId })

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (data != null) return <span>{data.sampleSetNumber}</span>

  return <span>Kein Probenset gefunden</span>
}
