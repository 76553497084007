import { H2 } from "@/shared/components/typography"
import { AirSamplersTable } from "./_components/air-samplers-table"
import PutAirSamplerDialog from "./_components/put-airsampler-dialog"

export function AirSamplersRoute() {
  return (
    <div>
      <H2 className="flex w-full justify-between">
        <span>Luftkeimsammler</span>
        <PutAirSamplerDialog />
      </H2>
      <div className="pt-4 text-muted-foreground">
        Hier können Luftkeimsammler mit ihren Metadaten eingetragen werden.
      </div>
      <div className="pt-4">
        <AirSamplersTable />
      </div>
    </div>
  )
}
