import type { PropsWithChildren } from "react"

export function RouteTitle({ children }: PropsWithChildren) {
  return <div className="text-3xl font-bold">{children}</div>
}

export function MainTitle({ children }: PropsWithChildren) {
  return <div className="text-2xl font-bold">{children}</div>
}

export function SectionTitle({ children }: PropsWithChildren) {
  return <h3 className="text-xl font-bold text-gray-900">{children}</h3>
}

export function ParagraphTitle({ children }: PropsWithChildren) {
  return <h4 className="text-lg font-bold text-gray-900">{children}</h4>
}
