import { Input } from "@/shared/shadcn/components/ui/input"
import {
  PutRequest,
  usePutSampleSetMutation,
} from "@/services/backend/samples/sample-set/service"
import { SampleSet } from "@/services/backend/samples/sample-set/sample-set"
import { useState } from "react"
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce"

interface CommentInputComponentProps {
  sampleSet: SampleSet
}

export function CommentInputComponent({
  sampleSet,
}: CommentInputComponentProps) {
  const [request, setRequest] = useState<PutRequest>({
    ...sampleSet,
  })
  const [put, { isLoading, error, isSuccess, reset }] =
    usePutSampleSetMutation()

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    300,
    { toastError: true },
  )

  return (
    <div className="flex flex-row">
      <Input
        type="text"
        placeholder="Kommentar"
        defaultValue={sampleSet.comment}
        onChange={e => {
          if (e.target.value)
            setRequest({ ...request, comment: e.target.value })
        }}
        disabled={isLoading || sampleSet.completed}
      />
    </div>
  )
}
