import { ReactNode, useState } from "react"
import type {
  ColumnDef,
  Row,
  RowSelectionState,
  SortingState,
  Table as ReactTable,
  VisibilityState,
} from "@tanstack/react-table"
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/shadcn/components/ui/table"
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/shared/shadcn/components/ui/collapsible"
import { Button } from "@/shared/shadcn/components/ui/button"
import { ChevronDown, ChevronRight } from "lucide-react"

function CollapsibleRow<TData>({
  row,
  expandedRows,
  setExpandedRows,
  renderCollapsibleContent,
  collapsibleTriggerId,
}: {
  row: Row<TData>
  expandedRows: Record<string, boolean>
  setExpandedRows: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  renderCollapsibleContent: (row: TData) => React.ReactNode
  collapsibleTriggerId: string
}) {
  const isOpen = expandedRows[row.id] || false

  const toggleRow = () => {
    setExpandedRows(prev => ({
      ...prev,
      [row.id]: !prev[row.id],
    }))
  }

  return (
    <Collapsible open={isOpen} onOpenChange={toggleRow} asChild>
      <>
        <TableRow>
          {row.getVisibleCells().map(cell => {
            if (cell.column.id === collapsibleTriggerId) {
              return (
                <TableCell key={cell.id} className="p-0">
                  <CollapsibleTrigger asChild>
                    <div className="flex flex-row">
                      <Button variant="ghost" className="">
                        {isOpen ? (
                          <ChevronDown className="h-4 w-4" />
                        ) : (
                          <ChevronRight className="h-4 w-4" />
                        )}
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Button>
                    </div>
                  </CollapsibleTrigger>
                </TableCell>
              )
            }
            return (
              <TableCell key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            )
          })}
        </TableRow>
        <CollapsibleContent className="bg-gray-100" asChild>
          <TableRow>
            <TableCell colSpan={row.getVisibleCells().length}>
              <div>{renderCollapsibleContent(row.original)}</div>
            </TableCell>
          </TableRow>
        </CollapsibleContent>
      </>
    </Collapsible>
  )
}

export function useCollapsibleDataTableController<TData, TValue>(
  columns: ColumnDef<TData, TValue>[],
  data: TData[],
  columnVisibility: VisibilityState = {},
): ReactTable<TData> {
  const [visibility, setVisibility] =
    useState<VisibilityState>(columnVisibility)
  const [sorting, setSorting] = useState<SortingState>([])
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  return useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnVisibilityChange: setVisibility,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getSortedRowModel: getSortedRowModel(),
    state: {
      columnVisibility: visibility,
      sorting,
      rowSelection,
    },
  })
}

export interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  table: ReactTable<TData>
  renderCollapsibleContent: (row: TData) => ReactNode
  collapsibleTriggerId: string
}

export function CollapsibleDataTable<TData, TValue>({
  table,
  columns,
  renderCollapsibleContent,
  collapsibleTriggerId,
}: DataTableProps<TData, TValue>) {
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({})
  return (
    <div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table
                .getRowModel()
                .rows.map(row => (
                  <CollapsibleRow
                    key={row.id}
                    row={row}
                    expandedRows={expandedRows}
                    setExpandedRows={setExpandedRows}
                    renderCollapsibleContent={renderCollapsibleContent}
                    collapsibleTriggerId={collapsibleTriggerId}
                  />
                ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length + 1}
                  className="h-24 text-center"
                >
                  Keine Ergebnisse.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
